<!--
 * @Author       : JiangChao
 * @Date         : 2025-01-08 13:36:35
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-08 13:39:45
 * @Description  : 设备详情页-测量数据
-->
<template>
  <div class="measurements">
    <el-row class="measurements-title">
      <el-col :span="8"></el-col>
      <el-col :span="8"
        ><span class="label">{{ $t("latest_measurements") }}</span></el-col
      >
    </el-row>

    <div class="measurements-box">
      <div class="measurements-up">
        <div class="left1">
          <span
            class="label"
            :style="deviceDataInfo.temp ? '' : 'color:#d9d9d9'"
            >{{ $t("temperature") }}</span
          >
          <div class="left1_1">
            <img
              :src="deviceDataInfo.temp ? icTemperature : icTemperatureGray"
              alt=""
            />
            <span
              class="value font-heavy"
              v-if="deviceDataInfo.temp"
              :style="deviceDataInfo.temp ? '' : 'color:#d9d9d9'"
              >{{
                Number(deviceDataInfo.temp)
                  | convertTemperature(setSelectedTempType)
              }}{{ setSelectedTempType }}</span
            >
            <span class="value" v-else-if="!deviceDataInfo.temp">-</span>
          </div>
          <span style="color: #d9d9d9">{{
            deviceDataInfo.refreshDate
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}</span>
        </div>
        <div class="left2">
          <span
            class="label"
            :style="deviceDataInfo.wetness ? '' : 'color:#d9d9d9'"
            >{{ $t("humidity") }}</span
          >
          <div class="left2_2">
            <img
              :src="deviceDataInfo.wetness ? icHumidity : icHumidityGray"
              alt=""
            />
            <span
              class="value font-heavy"
              :style="deviceDataInfo.wetness ? '' : 'color:#d9d9d9'"
              >{{ deviceDataInfo.wetness || "-" }}</span
            >
          </div>
        </div>
        <div class="right1">
          <span
            class="label"
            :style="deviceDataInfo.temp1 ? '' : 'color:#d9d9d9'"
            >{{ $t("temperature") }}</span
          >
          <div class="right1_1">
            <img
              :src="deviceDataInfo.temp1 ? icTemperature : icTemperatureGray"
              alt=""
            />
            <span class="value font-heavy" v-if="deviceDataInfo.temp1"
              >{{
                Number(deviceDataInfo.temp1)
                  | convertTemperature(setSelectedTempType)
              }}{{ setSelectedTempType }}</span
            >
            <span
              class="value font-heavy"
              :style="deviceDataInfo.temp1 ? '' : 'color:#d9d9d9'"
              v-else-if="!deviceDataInfo.temp1"
              >-</span
            >
          </div>
          <span
            v-if="deviceDataInfo.temp1 && deviceDataInfo.wetness1"
            style="color: #d9d9d9"
            >{{
              deviceDataInfo.refreshDate
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
            }}</span
          >
          <span
            style="color: #d9d9d9"
            v-if="!deviceDataInfo.temp1 && !deviceDataInfo.wetness1"
            >{{ $t("no_external_sensor") }}</span
          >
        </div>
        <div class="right2">
          <span
            class="label"
            :style="deviceDataInfo.wetness1 ? '' : 'color:#d9d9d9'"
            >{{ $t("humidity") }}</span
          >
          <div class="right2_2">
            <img
              :src="deviceDataInfo.wetness1 ? icHumidity : icHumidityGray"
              alt=""
            />

            <span
              class="value font-heavy"
              :style="deviceDataInfo.wetness1 ? '' : 'color:#d9d9d9'"
              >{{ deviceDataInfo.wetness1 || "-" }}</span
            >
          </div>
        </div>
      </div>
      <div class="measurements-down">
        <div class="left" v-if="deviceDataInfo.light">
          <span
            class="label"
            :style="deviceDataInfo.light.value ? '' : 'color:#d9d9d9'"
            >{{ $t("light_intensity") }}</span
          >
          <div class="left_1">
            <img
              :src="deviceDataInfo.light.value ? icLight : icLightGray"
              alt=""
            />
            <span
              class="value font-heavy"
              :style="deviceDataInfo.light.value ? '' : 'color:#d9d9d9'"
              >{{ deviceDataInfo.light.value || "-" }}Lux</span
            >
          </div>
          <span style="color: #d9d9d9">{{
            deviceDataInfo.light.timestamp
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}</span>
        </div>

        <div class="right">
          <span
            class="label"
            :style="
              deviceDataInfo.shock && deviceDataInfo.shock.value
                ? ''
                : 'color:#d9d9d9'
            "
            >{{ $t("vibrate") }}</span
          >
          <div class="right_1">
            <img
              :src="
                deviceDataInfo.shock && deviceDataInfo.shock.value
                  ? icVibrate
                  : icVibrateGray
              "
              alt=""
            />
            <span
              class="value font-heavy"
              :style="
                deviceDataInfo.shock && deviceDataInfo.shock.value
                  ? ''
                  : 'color:#d9d9d9'
              "
              >{{
                (deviceDataInfo.shock && deviceDataInfo.shock.value) || "-"
              }}mg</span
            >
          </div>
          <span style="color: #d9d9d9">{{
            deviceDataInfo.shock &&
              deviceDataInfo.shock.timestamp
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeasurementsData",
  data() {
    return {
      icTemperatureGray: require("@/assets/ic_temperature_gray.png"),
      icTemperature: require("@/assets/ic_temperature.png"),
      icHumidityGray: require("@/assets/ic_humidity_gray.png"),
      icHumidity: require("@/assets/ic_humidity.png"),
      icLightGray: require("@/assets/ic_light_gray.png"),
      icLight: require("@/assets/ic_light.png"),
      icVibrateGray: require("@/assets/ic_vibrate_gray.png"),
      icVibrate: require("@/assets/ic_vibrate.png"),
    };
  },
  methods: {},
  props: { deviceDataInfo: { type: Object, default: () => {} } },
  components: {},
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">
.measurements {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .label {
    font-weight: 500;
    font-size: 15px;
  }
  .value {
    font-size: 30px;
  }
  .measurements-title {
    background: #e8e9ef;
    width: 100%;
    height: 50px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .measurements-box {
    background: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .measurements-up {
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 20px;

      .left1 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 50%;
        .left1_1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 26px;
            margin: 0 10px 0 0;
          }
        }
      }
      .left2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 50%;
        .left2_2 {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 26px;
            margin: 0 10px 0 0;
          }
        }
      }

      .right1 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 50%;
        margin-left: 40px;

        .right1_1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 26px;
            margin: 0 10px 0 0;
          }
        }
      }
      .right2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 50%;
        .right2_2 {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 26px;
            margin: 0 10px 0 0;
          }
        }
      }
    }

    .h-line {
      width: 100%;
      height: 1px;
      background: #ececec;
    }
    .measurements-down {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      margin-left: 20px;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 50%;

        .left_1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 26px;
            margin: 0 10px 0 0;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 50%;
        margin-left: 40px;
        .right_1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 26px;
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
}
</style>
