<!--
 * @Author       : JiangChao
 * @Date         : 2024-09-13 14:42:19
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-02 15:04:45
 * @Description  : 选择已有规则弹窗
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="85%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("select_existed_rules") }}
      </div>
    </template>
    <el-form
      :model="form"
      status-icon
      ref="resetRef"
      label-width="130px"
      class="demo-ruleForm"
    >
      <div class="top">
        <div class="left">
          <el-input
            :placeholder="$t('please_enter_keyword')"
            clearable
            style="flex: 1; width: 300px"
            v-model="form.keyword"
            autocomplete="off"
            class="input-select-custom-width"
          >
          </el-input>
          <el-button
            style="
              width: 145px;
              margin-left: 10px;
              background: #1a72f4;
              border: 1px solid #1a72f4;
            "
            type="primary"
            @click="getAlertList"
            >{{ $t("query") }}</el-button
          >
        </div>
        <div class="right">
          <el-button
            style="width: 145px; border: 1px solid #1a72f4; color: #1a72f4"
            plain
            @click="dialogFormVisible = false"
            >{{ $t("cancel") }}</el-button
          >
          <el-button
            style="width: 145px; background: #1a72f4; border: 1px solid #1a72f4"
            type="primary"
            @click="handelOk"
          >
            {{ $t("confirm") }}</el-button
          >
        </div>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      :cell-style="{ 'text-align': 'center' }"
      @selection-change="handleSelectionChange"
      :empty-text="$t('no_data')"
    >
      <el-table-column type="selection" width="55"> </el-table-column>

      <el-table-column :label="$t('affiliated_scene')" prop="name">
        <template v-slot:default="scope">
          <!-- <span class="scene-detail" @click="goAlertDetail(scope.row)">
            {{ scope.row.name }}</span
          > -->
          <span> {{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('alert_rules')" min-width="260">
        <template v-slot:default="scope">
          <div
            v-for="(item, index) in scope.row.alarmDesc"
            :key="index"
            class="alarm-desc"
          >
            <span class="font-heavy"> {{ item.type | AlarmType }} </span>:
            {{ item.source | AlarmSensor }}
            <span v-if="item.min && item.max" style="margin-left: 5px">
              is out of the range of</span
            >
            <span
              v-if="item.source == '2' || item.source == '8'"
              style="margin-left: 5px"
            >
              {{ item.max }}%~{{ item.min }}%
            </span>
            <div v-else style="margin-left: 5px">
              <!-- 
              大于号：> 或 &gt;
              小于号：< 或 &lt;
              大于等于：≥ 可以用 &ge; （显示为 ≥）
              小于等于：≤ 可以用 &le; （显示为 ≤） 
              -->
              <span v-if="item.min && !item.max">
                &gt;{{ item.min | convertTemperature(setSelectedTempType) }}
                {{ setSelectedTempType }}
              </span>
              <span v-else-if="!item.min && item.max">
                &lt;{{ item.min | convertTemperature(setSelectedTempType) }}
                {{ setSelectedTempType }}
              </span>
              <span v-else>
                {{ item.min | convertTemperature(setSelectedTempType) }} ~
                {{ item.max | convertTemperature(setSelectedTempType) }}
                {{ setSelectedTempType }}
              </span>
            </div>

            <span v-if="item.type == '2'" style="margin-left: 5px"
              >more than {{ item.duration }} minutes</span
            >
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('apply_for')">
        <template v-slot:default="scope">
          <div class="add-device-box">
            <div class="left">
              <span v-if="scope.row.deviceIds.length > 0">
                {{ scope.row.deviceIds | deviceIdsSeparated }}
              </span>
              <span v-if="scope.row.deviceIds.length <= 0"> </span>
            </div>
            <!-- <div class="right">
              <img
                src="../../assets/ic_plus_blue.png"
                @click="selectDevice(scope.row.sceneId)"
                class="button-custom-height-30"
              />
            </div> -->
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('note')" prop="remark">
        <template v-slot:default="scope">
          <div class="edit-box">
            <span> {{ scope.row.remark}}</span>
            <!-- <img
              :src="scope.row.ownerInd == 1 ? isEdit : isEditGray"
              @click="changeAlertNote(scope.row)"
              :style="
                scope.row.ownerInd == 1
                  ? 'cursor: pointer'
                  : 'cursor: not-allowed'
              "
            /> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: center"
      background
      @current-change="handleCurrentChange"
      :current-page="Number(this.pageNum)"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="Number(this.pageSize)"
      layout="total,pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-dialog>
</template>

<script>
import { getSceneListApi, applyAlarmSettingApi } from "@/api/api.js";

export default {
  name: "SelectExistedRulesDialog",
  data() {
    return {
      dialogFormVisible: false,
      tableData: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      form: {},
      selectedRows: [], // 存储当前选中的行      isChina: true,
      sceneId: "",
      filters: [],
      deviceId: "",
    };
  },
  methods: {
    showDialog(id) {
      this.deviceId = id;
      this.dialogFormVisible = true;
      this.getAlertList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      // this.queryParams.page.pageIndex = val;
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getAlertList();
    },
    getAlertList() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        timezone: this.timezone,
        filters: this.filters,
        userId: "",
        ...this.form,
      };

      getSceneListApi(params).then((response) => {
        this.tableData = response.data.records;
        console.log("response: ", response);
      });
    },
    handleSelectionChange(rows) {
      console.log("rows: ", rows);
      this.selectedRows = rows;
    },

    handelOk() {
      console.log("this.selectedRows: ", this.selectedRows);
      if (this.selectedRows.length <= 0) {
        this.$message.error(this.$t("at_least_select_one_device"));
        return;
      }
      if (this.selectedRows.length > 1) {
        this.$message.error(this.$t("select_one_rule_tip"));
        return;
      }
      let temp = [];
      temp.push(this.deviceId);

      let params = {
        sceneId: this.selectedRows[0].sceneId,
        deviceIds: temp,
      };
      console.log("params: ", params);

      applyAlarmSettingApi(params).then((r) => {
        console.log("r: ", r);
        this.$message.success(this.$t("operation_successful"));
        this.dialogFormVisible = false;
        this.$emit("refresh");
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
    selectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
      this.getAlertList();
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.timezone = this.selectedTimeZone;

    this.isChina = JSON.parse(localStorage.getItem("isChina"));
  },
  created() {},
};
</script>

<style scoped lang="less">
.measurements-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.alert-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    margin: 0 5px;
  }
}
.demo-ruleForm {
  margin-top: 20px;
}
.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 0;
  .left {
    display: flex;
    flex-direction: row;
  }
}

.status-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .point {
    width: 6px;
    height: 6px;

    border-radius: 3px;
    margin-right: 4px;
  }
}
.add-device-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: 0 40px;
  .button-custom-height-30 {
    height: 30px;
    line-height: 30px; /* 确保文本垂直居中 */
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid transparent;
    color: #1a72f4;
    text-decoration: underline;
  }
}
.alarm-desc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
</style>
