<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-23 14:25:12
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-20 09:00:18
 * @Description  : 组织详情
-->
<template>
  <div class="container">
    <div class="top">
      <div>
        <div class="organ-name font-heavy">
          {{ organData.name }}
        </div>
        <div class="id">
          <div style="font-size: 16px; font-style: normal">
            {{ $t("invite_code") }}：{{ inviteCode || "-" }}
          </div>
          <img
            src="../../assets/ic_refresh_code.png"
            width="24"
            height="24"
            alt=""
            style="margin-left: 10px"
            @click="refreshInvitationCode"
          />
        </div>
        <div class="manager">
          {{ $t("owner") }}：{{ organData.ownerName || "-" }}
        </div>
        <div class="remark">
          {{ $t("remark") }}： {{ organData.remark }}
          <img
            src="../../assets/ic_edit.png"
            alt=""
            width="15"
            @click="changeOrganNote"
          />
        </div>
      </div>
      <div class="permission">
        <span> {{ $t("permission_management") }}：</span>
        <div>
          <el-button
            type="primary"
            @click="transferOrgan"
            v-if="this.organData.ownerInd == 1 && this.organData.limits == 1"
            >{{ $t("transfer_organization_ownership") }}</el-button
          >
          <el-button
            type="primary"
            @click="deleteOrg"
            style="background: #ed6e36; border: 1px solid #ed6e36"
            v-if="this.organData.ownerInd == 1 && this.organData.limits == 1"
            >{{ $t("delete_organ") }}</el-button
          >
          <el-button
            type="primary"
            @click="outOrg"
            v-if="this.organData.ownerInd == 0"
            >{{ $t("out_organ") }}</el-button
          >
        </div>
      </div>
    </div>
    <!-- 组织成员 -->
    <div class="table-title">
      <span class="font-heavy"> {{ $t("organization_members") }}</span>

      <div
        class="plus"
        @click="addMemberToOrganization()"
        v-if="this.organData.limits == 1"
      >
        <img src="../../assets/ic_plus.png" alt="" />
      </div>
    </div>
    <el-row class="btn-style" v-if="memberSelection.length > 0">
      <el-col :span="8"></el-col>
      <el-col class="tip" :span="8"
        >{{ $t("already_selected") }} {{ this.memberSelection.length }}
        {{ $t("number_of_members") }}</el-col
      >
      <el-col :span="4" :offset="4"> </el-col>
    </el-row>
    <el-table
      ref="memberTable"
      :data="memberData"
      stripe
      @selection-change="handleMemberSelectionChange"
      border
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      :cell-style="{ 'text-align': 'center' }"
      class="table-class"
      :empty-text="$t('no_data')"
    >
      <!-- <el-table-column
        type="selection"
        width="55"
        v-if="organData.ownerInd == 1"
      >
      </el-table-column> -->
      <el-table-column prop="name" :label="$t('member_name')">
      </el-table-column>
      <el-table-column prop="limits" :label="$t('role')">
        <template v-slot:default="scope">
          <span v-if="scope.row.ownerInd == '1'">{{ $t("owner") }} </span>
          <span v-else-if="scope.row.ownerInd != '1' && scope.row.limits == '1'"
            >{{ $t("administrator") }}
          </span>
          <span v-else-if="scope.row.ownerInd != '1' && scope.row.limits == '0'"
            >{{ $t("member") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="manager_permissions" :label="$t('operation')">
        <template v-slot:default="scope">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 40px;
            "
          >
            <div class="btn-cell-style">
              <el-button
                type="primary"
                @click="setAdmin(scope.row.userId)"
                class="button-custom-height-30"
                v-if="organData.ownerInd == 1 && scope.row.limits == 0"
                :style="
                  scope.row.ownerInd == 1
                    ? `background: transparent;border: 1px solid transparent;color:#c0c2cb;  text-decoration: underline; ;display:none `
                    : ` background: transparent;border: 1px solid transparent;color:#1a72f4;  text-decoration: underline;`
                "
                :disabled="scope.row.ownerInd == 1"
              >
                {{ $t("set_admin") }}</el-button
              >
              <el-button
                type="primary"
                @click="cancelAdmin(scope.row.userId)"
                class="button-custom-height-30"
                v-if="organData.ownerInd == 1 && scope.row.limits == 1"
                :style="
                  scope.row.ownerInd == 1
                    ? `background: transparent;border: 1px solid transparent;color:#c0c2cb;  text-decoration: underline;display:none  `
                    : ` background: transparent;border: 1px solid transparent;color:#1a72f4;  text-decoration: underline;`
                "
                :disabled="scope.row.ownerInd == 1"
              >
                {{ $t("set_member") }}</el-button
              >
            </div>

            <div class="btn-cell-style">
              <el-button
                type="primary"
                @click="deleteMember(scope.row.userId)"
                class="button-custom-height-30"
                v-if="scope.row.limits == 0"
                :style="
                  scope.row.ownerInd == 1
                    ? `background: transparent;border: 1px solid transparent;color:#c0c2cb;  text-decoration: underline; ;display:none `
                    : ` background: transparent;border: 1px solid transparent;color:#ed6e36;  text-decoration: underline;`
                "
                :disabled="scope.row.ownerInd == 1"
                >{{ $t("delete_member") }}</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="delete_member" :label="$t('remark')">
        <template v-slot:default="scope">
          <div class="remark-box">
            <span>{{ scope.row.remark }}</span>
            <img
              src="../../assets/ic_edit.png"
              alt=""
              width="15"
              v-if="organData.limits == 1"
              @click="addRemark(scope.row)"
              style="cursor: pointer"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: center"
      background
      @current-change="handleOrganCurrentChange"
      :current-page="Number(this.organPageNum)"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="Number(this.organPageSize)"
      layout="total,pager, next, jumper"
      :total="organTotal"
    >
    </el-pagination>

    <!-- 场景列表 -->
    <div class="scene-title">
      <span class="font-heavy">
        {{ $t("alert_rules_key") }} {{ $t("of") }} {{ organData.name }}</span
      >

      <div class="operate-box" v-if="this.organData.limits == 1">
        <div class="plus">
          <img
            src="../../assets/ic_plus.png"
            alt=""
            @click="addSceneToOrganization"
          />
        </div>

        <div class="recycle">
          <img src="../../assets/ic_recycle.png" alt="" @click="deleteScene" />
        </div>
      </div>
    </div>

    <el-row class="btn-style" v-if="sceneSelection.length > 0">
      <el-col :span="8"></el-col>
      <el-col class="tip" :span="8"
        >{{ $t("already_selected") }} {{ this.sceneSelection.length }}
        {{ $t("number_of_rules") }}</el-col
      >
      <el-col :span="4" :offset="4"> </el-col>
    </el-row>
    <el-table
      :data="sceneList"
      border
      stripe
      ref="sceneTable"
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      @selection-change="handleSceneSelectionChange"
      class="table-class"
      :empty-text="$t('no_data')"
    >
      <el-table-column
        type="selection"
        width="55"
        v-if="organData.ownerInd == 1"
      >
      </el-table-column>
      <el-table-column :label="$t('scene_name')" prop="scene_name">
        <template v-slot:default="scope">
          <!-- <span class="organ-detail" @click="goSceneDetail(scope.row)"> -->
          {{ scope.row.name }}
          <!-- </span> -->
        </template>
      </el-table-column>
      <el-table-column :label="$t('alert_rules')" min-width="260">
        <template v-slot:default="scope">
          <div
            v-for="(item, index) in scope.row.alarmDesc"
            :key="index"
            class="alarm-desc"
          >
            <span class="font-heavy"> {{ item.type | AlarmType }} </span>:
            {{ item.source | AlarmSensor }}
            <span style="margin-left: 5px"> is out of the range of</span>
            <!-- "1": "Temperature",
    "2": "Humidity",
    "4": "Probe temperature",
    "8": "Probe Humidity",
    "16": "Battery",
    "32": "Light",
    "64": "Shock", -->
            <span
              v-if="item.source == '2' || item.source == '8'"
              style="margin-left: 5px"
            >
              {{ item.max }}%~{{ item.min }}%
            </span>
            <div v-else style="margin-left: 5px">
              <!-- 
              大于号：> 或 &gt;
              小于号：< 或 &lt;
              大于等于：≥ 可以用 &ge; （显示为 ≥）
              小于等于：≤ 可以用 &le; （显示为 ≤） 
              -->
              <span v-if="item.source == '1' || item.source == '4'">
                {{ item.min | convertTemperature(setSelectedTempType) }} ~
                {{ item.max | convertTemperature(setSelectedTempType) }}
                {{ setSelectedTempType }}
              </span>
              <span v-if="item.source == '16'"> &lt;{{ item.min }} %</span>
              <span v-if="item.source == '32'"> &gt;{{ item.max }} Lux </span>
              <span v-if="item.source == '64'"> &gt;{{ item.max }} mg </span>
              <!-- <span v-if="item.min && !item.max">
                  &gt;{{ item.min | convertTemperature(setSelectedTempType) }}
                  {{ setSelectedTempType }}
                </span>
                <span v-else-if="!item.min && item.max">
                  &lt;{{ item.min | convertTemperature(setSelectedTempType) }}
                  {{ setSelectedTempType }}
                </span>
                <span v-else>
                  {{ item.min | convertTemperature(setSelectedTempType) }} ~
                  {{ item.max | convertTemperature(setSelectedTempType) }}
                  {{ setSelectedTempType }}
                </span> -->
            </div>

            <span v-if="item.type == '2'" style="margin-left: 5px"
              >more than {{ item.duration }} minutes</span
            >
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column :label="$t('number_of_loggers')" prop="sensorNum">
      </el-table-column>
      <el-table-column :label="$t('alert_setting')" prop="alarmSumNum">
      </el-table-column>
      <el-table-column :label="$t('loggers_with_alerts')">
        <template v-slot:default="scope">
          <span> {{ scope.row.alarmNum }}</span>
        </template>
      </el-table-column> -->

      <el-table-column :label="$t('apply_for')">
        <template v-slot:default="scope">
          <div class="add-device-box">
            <div class="left">
              <span v-if="scope.row.deviceIds.length > 0">
                {{ scope.row.deviceIds | deviceIdsSeparated }}
              </span>
              <span v-if="scope.row.deviceIds.length <= 0"> </span>
            </div>
            <div class="right">
              <img
                src="../../assets/ic_plus_blue.png"
                @click="selectDevice(scope.row.sceneId)"
                class="button-custom-height-30"
              />
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('note')" prop="remark">
        <template v-slot:default="scope">
          <div class="edit-box">
            <span> {{ scope.row.remark }}</span>
            <img
              :src="scope.row.ownerInd == 1 ? isEdit : isEditGray"
              @click="changeAlertNote(scope.row)"
              :style="
                scope.row.ownerInd == 1
                  ? 'cursor: pointer'
                  : 'cursor: not-allowed'
              "
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: center"
      background
      @current-change="handleSceneCurrentChange"
      :current-page="Number(this.scenePageNum)"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="Number(this.scenePageSize)"
      layout="total,pager, next, jumper"
      :total="sceneTotal"
    >
    </el-pagination>

    <!-- 设备列表 -->
    <div class="device-title">
      <span class="font-heavy">
        {{ $t("loggers_list") }} {{ $t("of") }} {{ organData.name }}</span
      >
      <div class="operate-box" v-if="this.organData.limits == 1">
        <div class="plus" @click="addDeviceToOrganization">
          <img src="../../assets/ic_plus.png" alt="" />
        </div>

        <div class="recycle" @click="deleteDevice">
          <img src="../../assets/ic_recycle.png" alt="" />
        </div>
      </div>
    </div>
    <el-row class="btn-style" v-if="loggerSelection.length > 0">
      <el-col :span="8"></el-col>
      <el-col class="tip" :span="8"
        >{{ $t("already_selected") }} {{ this.loggerSelection.length }}
        {{ $t("number_of_devices") }}</el-col
      >
      <el-col :span="4" :offset="4"> </el-col>
    </el-row>

    <el-table
      :data="loggerData"
      ref="loggerTable"
      stripe
      @selection-change="handleLoggerSelectionChange"
      border
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      :cell-style="{ 'text-align': 'center' }"
      class="table-class"
      :empty-text="$t('no_data')"
    >
      <el-table-column
        type="selection"
        width="55"
        v-if="organData.ownerInd == 1"
      >
      </el-table-column>
      <el-table-column :label="$t('device_id')" prop="deviceId" width="140px">
        <template v-slot:default="scope">
          <span class="organ-detail" @click="goDeviceDetail(scope.row)">
            {{ scope.row.deviceId }}</span
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('device_name')" prop="name">
        <template v-slot:default="scope">
          <span> {{ scope.row.name || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('device_status')" prop="status">
        <template v-slot:default="scope">
          <span v-if="scope.row.status == '0'">{{ $t("not_started") }}</span>
          <span v-if="scope.row.status == '1'">{{ $t("recording") }}</span>
          <span v-if="scope.row.status == '2'">{{
            $t("delay_recording")
          }}</span>
          <span v-if="scope.row.status == '3'">{{ $t("ended") }}</span>
          <span v-if="scope.row.status == '9'">{{ $t("discard") }}</span>
        </template>
      </el-table-column>
      <!-- 告警状态 1：告警 0：未告警 (设备有过告警，就一直记录为告警) -->
      <el-table-column
        :label="$t('alert_status')"
        prop="alarmInd"
        width="150px"
      >
        <template v-slot:default="scope">
          <div v-if="scope.row.alarmInd == 1" class="alert-style">
            <img src="../../assets/ic_alert.png" width="15" /><span>{{
              scope.row.alarmNum
            }}</span>
          </div>
          <div v-else-if="scope.row.alarmInd == 0" class="alert-style">
            <img src="../../assets/ic_no_alert.png" width="15" /><span>{{
              $t("no_alert")
            }}</span>
          </div>
        </template>
      </el-table-column>
      <!-- temp温度 wetness湿度 power电量 refreshDate最后更新时间 -->
      <el-table-column :label="$t('latest_measurements')" min-width="285">
        <template v-slot:default="scope">
          <div class="measurements-col">
            {{ Number(scope.row.temp) | convertTemperature(setSelectedTempType)
            }}{{ setSelectedTempType }}
            <span style="margin-left: 5px"> {{ scope.row.wetness }}</span>
            <!-- 0-20% 20-50% 50-90% 90-100% -->
            <template v-if="scope.row.battery">
              <BatteryInfo
                v-if="scope.row.battery"
                :value="scope.row.battery.value"
              ></BatteryInfo>
            </template>
            <span style="margin-left: 5px">
              {{
                scope.row.refreshDate
                  | formatDateWithTimeZone(
                    setSelectedTimeZone,
                    setSelectedLanguage
                  )
              }}
            </span>
          </div>
        </template>
      </el-table-column>
      <!-- 纬度latitude 经度longitude -->
      <el-table-column :label="$t('latest_location')" prop="" width="150px">
        <template v-slot:default="scope">
          <div class="location-col">
            {{ scope.row.address || "-" }}
            {{
              scope.row.addressTime
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
            }}
            <!-- {{ scope.row.longitude }} {{ scope.row.latitude }} -->
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('start_time')" prop="startTime" width="150px">
        <template v-slot:default="scope">
          {{
            scope.row.startTime
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('scene_name')" prop="sceneName">
        <template v-slot:default="scope">
          <span> {{ scope.row.sceneName || "-" }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: center"
      background
      @current-change="handleDeviceCurrentChange"
      :current-page="Number(this.devicePageNum)"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="Number(this.devicePageSize)"
      layout="total,pager, next, jumper"
      :total="deviceTotal"
    >
    </el-pagination>
    <ChangeOrganNoteDialog
      ref="noteRef"
      @refresh="getOrgDetailById"
    ></ChangeOrganNoteDialog>
    <TransferOrganizationDialog ref="transRef"></TransferOrganizationDialog>
    <AddMemberToOrganDialog
      ref="addMemberToOrganRef"
      @refreshMember="getOrgUserList"
    ></AddMemberToOrganDialog>
    <AddDeviceToOrganDialog
      ref="addDeviceToOrganRef"
      @refreshDevice="getOrgDeviceList"
    ></AddDeviceToOrganDialog>
    <!-- <AddSceneToOrganDialog
      ref="addSceneToOrganRef"
      @refreshScene="getOrgSceneList"
    ></AddSceneToOrganDialog> -->
    <AddRemarkDialog
      ref="remarkRef"
      @refreshMember="getOrgUserList"
    ></AddRemarkDialog>
    <ChangeAlertRemarkDialog
      ref="alertRemarkRef"
      @refresh="getOrgSceneList"
    ></ChangeAlertRemarkDialog>
    <AddDeviceToNewAlertDialog
      ref="deviceRef"
      @ok="getOrgSceneList"
    ></AddDeviceToNewAlertDialog>
  </div>
</template>

<script>
import {
  getByOrgIdApi,
  orgUserListApi,
  orgDeviceListApi,
  orgDelApi,
  userOrgDeleteApi,
  refreshInvitationCodeApi,
  deviceOutOrgApi,
  editLimitApi,
  sceneDeleteApi,
  getOrgSceneListApi,
  userQuitApi,
} from "@/api/api.js";
import ChangeOrganNoteDialog from "./modal/ChangeOrganNoteDialog.vue";
import TransferOrganizationDialog from "./modal/TransferOrganizationDialog.vue";
import AddMemberToOrganDialog from "./modal/AddMemberToOrganDialog.vue";
import AddDeviceToOrganDialog from "./modal/AddDeviceToOrganDialog.vue";
import ChangeAlertRemarkDialog from "../alert/modal/ChangeAlertRemarkDialog.vue";
import AddDeviceToNewAlertDialog from "../alert/modal/AddDeviceToNewAlertDialog.vue";

// import AddSceneToOrganDialog from "./modal/AddSceneToOrganDialog.vue";
import AddRemarkDialog from "./modal/AddRemarkDialog.vue";
// import config from "@/env.config.js";
import BatteryInfo from "../components/BatteryInfo.vue";

export default {
  name: "OrganDetail",
  data() {
    return {
      isEdit: require("@/assets/ic_edit.png"),
      isEditGray: require("@/assets/ic_edit_gray.png"),
      organData: {},
      memberData: [],
      loggerData: [],
      orgId: "",
      orgName: "",
      note: "",
      memberSelection: [],
      loggerSelection: [],
      sceneSelection: [],
      sceneList: [],
      inviteCode: "",
      organPageSize: 10,
      organPageNum: 1,
      devicePageSize: 10,
      devicePageNum: 1,
      scenePageSize: 10,
      scenePageNum: 1,
      deviceTotal: 0,
      organTotal: 0,
      sceneTotal: 0,
      userId: "",
      isChina: true,
    };
  },
  methods: {
    getOrgDetailById() {
      let params = {
        orgId: this.orgId,
      };
      getByOrgIdApi(params).then((r) => {
        console.log("=====", r);
        this.organData = r.data;
      });
    },
    refreshInvitationCode() {
      let params = {
        orgId: this.orgId,
        name: "",
        owner: "",
        remark: "",
      };
      refreshInvitationCodeApi(params).then((r) => {
        console.log("=====", r);
        this.inviteCode = r.data;
      });
    },
    getOrgUserList() {
      let params = {
        orgId: this.orgId,
        pageSize: this.organPageSize,
        pageNum: this.organPageNum,
      };
      orgUserListApi(params).then((r) => {
        console.log("=====", r);
        this.memberData = r.data.records || [];
        this.organTotal = r.data.total || [];
      });
    },
    handleCurrentChange(val) {
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getOrgUserList();
    },

    getOrgDeviceList() {
      let params = {
        orgId: this.orgId,
        pageSize: this.devicePageSize,
        pageNum: this.devicePageNum,
      };
      orgDeviceListApi(params).then((r) => {
        console.log("=====", r);
        this.loggerData = r.data.records || [];
        this.deviceTotal = r.data.total;
      });
    },

    deleteOrg() {
      this.$alert(this.$t("key_delete_organization_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,

        type: "warning",
      }).then(() => {
        let params = {
          orgId: this.orgId,
          name: "", //组织名称
          owner: "", //创建人
          remark: "", //备注
        };
        orgDelApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("delete_successful"));
          this.goPages("/home/organ");
        });
      });
    },
    outOrg() {
      this.$alert(this.$t("out_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,

        type: "warning",
      }).then(() => {
        let params = {
          orgId: this.orgId,
          userId: this.userId,
        };
        userQuitApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("operation_successful"));
          this.goPages("/home/organ");
        });
      });
    },
    deleteScene() {
      if (this.sceneSelection.length <= 0) {
        this.$message.error(this.$t("at_least_select_one_scene"));
        return;
      }
      let temp = [];
      this.sceneSelection.forEach((element) => {
        temp.push(element.sceneId);
      });

      let commaSeparatedIds = temp.join(",");
      this.$alert(this.$t("delete_scene_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,

        type: "warning",
      }).then(() => {
        let params = {
          sceneId: commaSeparatedIds,
        };
        sceneDeleteApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("delete_successful"));
          this.getOrgSceneList();
        });
      });
    },
    selectDevice(id) {
      this.$refs.deviceRef.showDialog(id);
    },
    changeOrganNote() {
      this.$refs.noteRef.showDialog(this.orgId);
    },
    transferOrgan() {
      this.$refs.transRef.showDialog(this.orgId);
    },

    getOrgSceneList() {
      let params = {
        pageSize: this.scenePageSize,
        pageNum: this.scenePageNum,
        orgId: this.orgId,
      };
      getOrgSceneListApi(params).then((r) => {
        console.log("=====", r);
        this.sceneList = r.data.records || [];
        this.sceneTotal = r.data.total;
      });
    },
    addLoggers() {},
    deleteMember(id) {
      console.log("id: ", id);
      this.$alert(this.$t("delete_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,

        type: "warning",
      }).then(() => {
        let params = {
          orgId: this.orgId,
          userId: id,
        };
        userOrgDeleteApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("delete_successful"));
          this.getOrgUserList();
        });
      });
    },
    deleteLogger() {},
    toggleMemberSelection(rows) {
      console.log("rows: ", rows);
      if (rows) {
        rows.forEach((row) => {
          this.$refs.memberTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.memberTable.clearSelection();
      }
    },
    toggleLoggerSelection(rows) {
      console.log("rows: ", rows);
      if (rows) {
        rows.forEach((row) => {
          this.$refs.loggerTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.loggerTable.clearSelection();
      }
    },
    toggleSceneSelection(rows) {
      console.log("rows: ", rows);
      if (rows) {
        rows.forEach((row) => {
          this.$refs.sceneTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.sceneTable.clearSelection();
      }
    },
    handleMemberSelectionChange(val) {
      console.log("val: ", val);
      this.memberSelection = val;
    },
    handleLoggerSelectionChange(val) {
      console.log("val: ", val);
      this.loggerSelection = val;
    },
    handleSceneSelectionChange(val) {
      console.log("val: ", val);
      this.sceneSelection = val;
    },
    setAdmin(id) {
      console.log("id: ", id);
      let params = {
        orgId: this.orgId,
        limits: 1,
        userId: id,
      };
      editLimitApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        this.getOrgUserList();
      });
    },
    cancelAdmin(id) {
      console.log("id: ", id);
      let params = {
        orgId: this.orgId,
        limits: 0,
        userId: id,
      };
      editLimitApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        this.getOrgUserList();
      });
    },

    deleteAll() {},
    goDeviceDetail(row) {
      localStorage.setItem("selectedMenuItem", 3);
      this.$store.dispatch("updateSelectedMenuItem", 3);
      let obj = {
        deviceId: row.deviceId,
      };
      this.goPages("/loggerDetail", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },
    // goSceneDetail(row) {
    //   console.log("row: ", row);
    //   let obj = {
    //     sceneId: row.sceneId,
    //     name: row.name,
    //     limits: row.limits,
    //     imgUrl: config.baseURL + row.imgUrl,
    //     remark: row.remark,
    //     ownerName: row.ownerName,
    //   };
    //   console.log("obj: ", obj);

    //   this.goPages("/sceneDetail", {
    //     data: encodeURIComponent(JSON.stringify(obj)),
    //   });
    // },

    addMemberToOrganization() {
      this.$refs.addMemberToOrganRef.showDialog(this.orgId);
    },
    addDeviceToOrganization() {
      this.$refs.addDeviceToOrganRef.showDialog(this.orgId);
    },
    addSceneToOrganization() {
      // this.$refs.addSceneToOrganRef.showDialog(this.orgId);
      localStorage.setItem("selectedMenuItem", 2);
      this.$store.dispatch("updateSelectedMenuItem", 2);
      this.goPages("/createAlert", {
        orgId: this.orgId,
      });
    },
    addRemark(row) {
      this.$refs.remarkRef.showDialog(this.orgId, row.userId);
    },
    deleteDevice() {
      if (this.loggerSelection.length <= 0) {
        this.$message.error(this.$t("at_least_select_one_device"));
        return;
      }
      let temp = [];
      this.loggerSelection.forEach((element) => {
        temp.push(element.deviceId);
      });

      this.$alert(this.$t("delete_logger_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,
        type: "warning",
      }).then(() => {
        let params = {
          orgId: this.orgId,
          deviceIds: temp,
        };
        deviceOutOrgApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("delete_successful"));
          this.getOrgDeviceList();
        });
      });
    },
    handleDeviceCurrentChange(val) {
      this.devicePageNum = 1;
      this.devicePageNum = `${val}`;

      this.getOrgDeviceList();
    },

    handleOrganCurrentChange(val) {
      this.organPageNum = 1;
      this.organPageNum = `${val}`;

      this.getOrgUserList();
    },
    handleSceneCurrentChange(val) {
      this.scenePageNum = 1;
      this.scenePageNum = `${val}`;

      this.getOrgSceneList();
    },
    changeAlertNote(row) {
      console.log("row: ", row);
      if (row.ownerInd == 0) {
        return;
      }
      this.$refs.alertRemarkRef.showDialog(row.sceneId);
    },
  },
  props: {},
  components: {
    ChangeOrganNoteDialog,
    TransferOrganizationDialog,
    AddMemberToOrganDialog,
    AddDeviceToOrganDialog,
    ChangeAlertRemarkDialog,
    AddDeviceToNewAlertDialog,
    // AddSceneToOrganDialog,
    AddRemarkDialog,
    BatteryInfo,
  },
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    this.userId = localStorage.getItem("userId");
    console.log("this.userId: ", this.userId);
    let obj = JSON.parse(decodeURIComponent(this.$route.query.data));
    this.orgId = obj.orgId;
    this.orgName = obj.orgName;
    this.note = obj.note;
    this.inviteCode = obj.code;
    this.getOrgDetailById();
    this.getOrgUserList();
    this.getOrgDeviceList();
    this.getOrgSceneList();
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  padding: 34px 68px 72px 38px;
  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }
  .btn-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0 10px 0;
  }
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .organ-name {
      font-size: 48px;
      font-weight: 500;
      color: #262626;
      height: 68px;
      line-height: 68px;
    }
    .remark {
      margin: 10px 0;
      font-size: 16px;
      color: #262626;
      font-weight: 500;
      img {
        cursor: pointer;
      }
    }
    .id {
      margin: 10px 0;
      display: flex;
      font-size: 18px;
      flex-direction: row;
      align-items: center;
      color: #262626;
      span {
        font-size: 18px;
        color: #262626;
        margin-right: 10px;
      }
      img {
        cursor: pointer;
      }
    }
    .manager {
      margin: 10px 0;
      font-size: 16px;
      color: #262626;
      font-weight: 500;
    }
    .permission {
      margin: 10px 0;
      font-size: 16px;
      display: flex;

      align-items: center;
    }
  }
  .table-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 10px 0;
    color: #262626;
    font-weight: 500;
    span {
      margin-bottom: -40px;
    }
    .plus {
      background: #1a72f4;
      border-radius: 8px;
      width: 41px;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .scene-title {
    font-size: 16px;
    color: #262626;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    span {
      margin-bottom: -40px;
    }
    .operate-box {
      display: flex;

      .plus {
        background: #1a72f4;
        border-radius: 8px;
        width: 41px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
        }
      }

      .recycle {
        background: #ed6e36;
        border-radius: 8px;
        width: 41px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .device-title {
    font-size: 16px;
    color: #262626;
    padding: 10px 0;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      margin-bottom: -40px;
    }
    .operate-box {
      display: flex;

      .plus {
        background: #1a72f4;
        border-radius: 8px;
        width: 41px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
        }
      }

      .recycle {
        background: #ed6e36;
        border-radius: 8px;
        width: 41px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
.btn-style {
  background: #1a72f4;
  padding: 10px;

  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  .tip {
    color: white;
  }
}
.logger-detail {
  color: #1a72f4;
  text-decoration: underline;
  cursor: pointer;
}
/* 设置plain类型按钮的默认边框颜色 */
.el-button--plain {
  border: 1px solid #1a72f4;
  color: #1a72f4; /* 保持文字颜色与边框颜色一致，可根据需要调整 */
}

/* 当鼠标悬停在按钮上时，改变边框颜色 */
.el-button--plain:hover {
  border-color: darken(
    #1a72f4,
    10%
  ); /* 将边框颜色加深10%，你可以根据需要调整这个值 */
}

/* 当按钮被激活（按下）时，改变边框颜色 */
.el-button--plain.is-active {
  border-color: darken(
    #1a72f4,
    20%
  ); /* 将边框颜色加深20%，你可以根据需要调整这个值 */
}

/* 当按钮处于禁用状态时，改变边框颜色 */
.el-button--plain.is-disabled {
  border-color: lighten(
    #1a72f4,
    50%
  ); /* 将边框颜色变浅50%，你可以根据需要调整这个值 */
}
.measurements-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.alert-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    margin: 0 5px;
  }
}
.el-table__body td {
  line-height: 39px; /* 设置行高 */
  padding: 0 5px; /* 可选，根据需要调整 */
}

/* 或者更具体的类名，如果你不希望影响整个应用中的所有表格 */
.table-class .el-table__body td {
  line-height: 39px;
  padding: 0 5px;
  width: 100%;
}
/* styles.css */
.button-custom-height-30 {
  height: 30px;
  line-height: 30px; /* 确保文本垂直居中 */
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-disabled {
  background: #c0c2cb;
  border: 1px solid #c0c2cb;
  cursor: not-allowed; /* 更改鼠标指针样式 */
}

.button-custom-height-41 {
  height: 41px;
  font-size: 14px;
}

.btn-cell-style {
  display: flex;
  align-items: center;
  justify-content: center;
}
.organ-detail {
  color: #1a72f4;
  text-decoration: underline;
  cursor: pointer;
}
.remark-box {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-left: 5px;
  }
}
.edit-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
    margin-left: 15px;
    cursor: pointer;
  }
}
.alarm-desc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.add-device-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: 0 40px;
  .button-custom-height-30 {
    height: 30px;
    line-height: 30px; /* 确保文本垂直居中 */
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid transparent;
    color: #1a72f4;
    text-decoration: underline;
  }
}
</style>
