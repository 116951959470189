<!--
 * @Author       : JiangChao
 * @Date         : 2024-12-31 16:38:50
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-12-31 17:15:34
 * @Description  : 电量组件
-->
<template>
  <div class="box">
    <img
      v-if="Math.round(value) > 90 && Math.round(value) <= 100"
      :src="isRight ? icBatteryFullRight : icBatteryFull"
      :width="width"
    />
    <img
      v-if="Math.round(value) > 50 && Math.round(value) <= 90"
      :src="isRight ? icBatteryHalfRight : icBatteryHalf"
      :width="width"
    />
    <img
      v-if="Math.round(value) > 20 && Math.round(value) <= 50"
      :src="isRight ? icBatteryLowRight : icBatteryLow"
      :width="width"
    />
    <img
      v-if="Math.round(value) >= 0 && Math.round(value) <= 20"
      :src="isRight ? icBatteryEmptyRight : icBatteryEmpty"
      :width="width"
    />

    <span :style="{ marginLeft: marginLeft, color: color }"
      >{{ value || "-" }}%</span
    >
  </div>
</template>

<script>
export default {
  name: "BatteryInfo",
  data() {
    return {
      icBatteryFull: require("@/assets/ic_battery_full.png"),
      icBatteryHalf: require("@/assets/ic_battery_half.png"),
      icBatteryLow: require("@/assets/ic_battery_low.png"),
      icBatteryEmpty: require("@/assets/ic_battery_empty.png"),
      icBatteryFullRight: require("@/assets/ic_battery_full_right.png"),
      icBatteryHalfRight: require("@/assets/ic_battery_half_right.png"),
      icBatteryLowRight: require("@/assets/ic_battery_low_right.png"),
      icBatteryEmptyRight: require("@/assets/ic_battery_empty_right.png"),
    };
  },
  methods: {},
  props: {
    value: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "#606266",
    },
    isRight: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 20,
    },
    marginLeft:{
      type: String,
      default: "-2px"
    }
  },
  components: {},
  computed: {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">
.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
