import { render, staticRenderFns } from "./TransferOrganizationDialog.vue?vue&type=template&id=05315fa2&scoped=true&"
import script from "./TransferOrganizationDialog.vue?vue&type=script&lang=js&"
export * from "./TransferOrganizationDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05315fa2",
  null
  
)

export default component.exports