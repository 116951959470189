/*
 * @Author       : JiangChao
 * @Date         : 2024-04-15 14:06:42
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-12-31 10:34:13
 * @Description  :
 */
export default {
  selectedTimeZone: localStorage.getItem("timeZoneValue") || "0", // 存储选择的时区
  selectedTempType: localStorage.getItem("selectedTempType") || "°C", // 存储选择的温度类型
  selectedLanguage: localStorage.getItem("selectedLanguage") || "en", // 默认是中文
  selectedMenuItem: localStorage.getItem("selectedMenuItem") || 1 // 默认是中文
};
