<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-23 14:22:32
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-16 13:59:55
 * @Description  : 转移组织
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
   <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("transfer_organization") }}
      </div>
    </template>
    <div style="margin-bottom:10px">{{ $t("transfer_team_tip") }}</div>
    <el-form
      :model="form"
      status-icon
      ref="resetRef"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label-width="0px">
        <el-input
          clearable
          v-model="form.owner"
          autocomplete="off"
          :placeholder="placeholderTip"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { orgTransferApi } from "@/api/api.js";
export default {
  name: "TransferOrganizationDialog",
  data() {
    return {
      form: {
        owner: "",
      },
      dialogFormVisible: false,
      isChina: true,
      placeholderTip: "",
      orgId: "",
    };
  },
  methods: {
    showDialog(id) {
      this.form = {};
      this.orgId = id;
      this.dialogFormVisible = true;
    },

    handelOk() {
      let params = {
        ...this.form,
        // name: "",
        // remark: "",
        orgId: this.orgId,
      };

      orgTransferApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        this.dialogFormVisible = false;
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
      this.placeholderTip =
        this.isChina == true ? this.$t("phone_email") : this.$t("email");
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));

    console.log(" this.isChina: ", this.isChina);
  },
  created() {},
};
</script>

<style scoped lang="less"></style>
