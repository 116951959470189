/*
 * @Author       : JiangChao
 * @Date         : 2024-04-15 14:06:42
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-12-31 10:33:34
 * @Description  : 
 */

export default {
    selectedTimeZone: state => state.selectedTimeZone,
    selectedTempType: state => state.selectedTempType,
    selectedLanguage: state => state.selectedLanguage,
    selectedMenuItem: state => state.selectedMenuItem,
    
    
}