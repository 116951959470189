<!--
 * @Author       : JiangChao
 * @Date         : 2024-10-29 10:08:58
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-16 16:12:01
 * @Description  : 运单列表
-->
<template>
  <div class="container">
    <div class="btn-layout">
      <div class="title font-heavy">{{ $t("shipments") }}</div>

      <el-button
        type="primary"
        @click="addShipment"
        style="width: 165px; height: 41px"
        >+ {{ $t("new_shipment") }}</el-button
      >
    </div>
    <div class="table-layout">
      <div class="custom-tab-class">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="$t('tab_on_going') + '(' + ongoingCount + ')'"
            name="1"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('tab_not_start') + '(' + notStartCount + ')'"
            name="0"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('tab_ended') + '(' + endedCount + ')'"
            name="9"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('tab_all') + '(' + allCount + ')'"
            name="all"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <el-row class="btn-style" v-if="shipmentSelection.length > 0">
        <el-col :span="8"></el-col>
        <el-col class="tip" :span="8"
          >{{ $t("already_selected") }} {{ this.shipmentSelection.length }}
          {{ $t("number_of_shipments") }}</el-col
        >

        <el-col :span="4">
          <el-button
            size="small"
            @click="saveAsTemplate()"
            style="color: #1a72f4; width: 140px"
            >{{ $t("save_as_template") }}</el-button
          >
        </el-col>
        <el-col :span="3">
          <el-button
            size="small"
            @click="deleteShipment()"
            style="color: #1a72f4; width: 100px"
            >{{ $t("delete") }}</el-button
          >
        </el-col>

        <el-col :span="3">
          <el-button
            size="small"
            @click="cancel()"
            style="color: #1a72f4; width: 100px"
            >{{ $t("cancel") }}</el-button
          >
        </el-col>

        <!-- <el-button @click="toggleLoggerSelection()">取消选择</el-button> -->
      </el-row>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#f6f6fa',
          textAlign: 'center',
          color: 'black',
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleShipmentSelectionChange"
        :empty-text="$t('no_data')"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          :label="$t('table_key_shipment_name')"
          prop="name"
          width="170"
        >
          <template v-slot:default="scope">
            <div class="organ-detail">
              <el-popover
                v-if="shouldShowPopover(scope.row.name, 16)"
                placement="top-start"
                title=""
                width="200"
                trigger="hover"
                :content="scope.row.name"
              >
                <span slot="reference" @click="goShipmentDetail(scope.row)">
                  {{ scope.row.name | ellipsisText(16) }}
                </span>
              </el-popover>
              <span v-else @click="goShipmentDetail(scope.row)">
                {{ scope.row.name }}
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('shipment_id')"
          prop="shipmentId"
          width="170"
        >
          <template v-slot:default="scope">
            <div style="text-align: left">
              <span> {{ scope.row.shipmentId }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('alert_status')"
          prop="alarmNum"
          min-width="90"
        >
          <template v-slot:default="scope">
            <div v-if="scope.row.alarmInd == 1" class="alert-style">
              <img src="../../assets/ic_alert.png" width="15" /><span>{{
                scope.row.alarmNum
              }}</span>
            </div>
            <div v-else-if="scope.row.alarmInd == 0" class="alert-style">
              <img src="../../assets/ic_no_alert.png" width="15" /><span>{{
                $t("no_alert")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('table_key_shipment_status')"
          prop="status"
          min-width="90"
        >
          <template v-slot:default="scope">
            <div class="status-style">
              <div
                class="point"
                :style="
                  scope.row.status == '1'
                    ? 'background: #02C06F;'
                    : 'background: #ed6e36;'
                "
              ></div>
              <span v-if="scope.row.status == '1'">{{
                $t("tab_on_going")
              }}</span>

              <span v-if="scope.row.status == '0'" style="color: #ed6e36">{{
                $t("tab_not_start")
              }}</span>
              <span v-if="scope.row.status == '9'" style="color: #ed6e36">{{
                $t("tab_ended")
              }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('start_time')" prop="startTime" width="150">
          <template v-slot:default="scope">
            {{
              scope.row.startTime
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
            }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('end_time')" prop="endTime" width="150">
          <template v-slot:default="scope">
            {{
              scope.row.endTime
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
            }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('duration')"
          prop="duration"
          align="left"
          width="180"
        >
          <template v-slot:default="scope">
            <div style="text-align: left">
              {{ scope.row.duration }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('logger')"
          prop="deviceInfoList"
          width="220px"
        >
          <template v-slot:default="scope">
            <div
              v-if="scope.row.deviceInfoList.length > 0"
              style="text-align: left"
            >
              <span
                v-for="device in scope.row.deviceInfoList"
                :key="device.deviceId"
                @click="goDeviceDetail(device)"
                class="device-detail"
              >
                {{ formatDeviceName(device) }}<br />
              </span>
            </div>
            <div style="text-align: left" v-else>
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('note')" prop="remark" min-width="150">
          <template v-slot:default="scope">
            <div style="text-align: left">
              <el-popover
                v-if="shouldShowPopover(scope.row.remark, 12)"
                placement="top-start"
                title=""
                width="200"
                trigger="hover"
                :content="scope.row.remark"
              >
                <span slot="reference">
                  {{ scope.row.remark | ellipsisText(12) }}
                </span>
              </el-popover>
              <span v-else>
                {{ scope.row.remark }}
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: center"
        background
        @current-change="handleCurrentChange"
        :current-page="Number(this.pageNum)"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="Number(this.pageSize)"
        layout="total,pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- <AddOrganizationsDialog
      ref="addOrganizationRef"
      @ok="getShipmentList"
    ></AddOrganizationsDialog>
    <AddOrganFilterDialog
      ref="filterRef"
      @filters="getShipmentList"
    ></AddOrganFilterDialog> -->
  </div>
</template>

<script>
// import AddOrganizationsDialog from "./modal/AddOrganizationsDialog.vue";
// import AddOrganFilterDialog from "./modal/AddOrganFilterDialog.vue";
import {
  getShipmentListApi,
  deleteShipmentApi,
  getGroupByStatusListApi,
  saveShipmentTemplateApi,
} from "@/api/api.js";

export default {
  name: "Shipment",
  data() {
    return {
      tableData: [],
      shipmentSelection: [],
      search: "",
      total: 0,
      // rows: 30,
      orgId: "",
      pageSize: 10,
      pageNum: 1,
      timezone: 0,
      organFilterField: [],
      activeName: "1",
      // shipmentStatus: 1,
      ongoingCount: 0,
      notStartCount: 0,
      endedCount: 0,
      allCount: 0,
    };
  },
  methods: {
    handleClick(val) {
      console.log("val: ", val.name);
      this.activeName = val.name;
      this.pageNum = 1;

      this.getGroupByStatusList();
      this.getShipmentList();
    },
    formatDeviceName(device) {
      return `${device.name} - #${device.deviceId}`;
    },
    goDeviceDetail(row) {
      localStorage.setItem("selectedMenuItem", 3);
      this.$store.dispatch("updateSelectedMenuItem", 3);
      console.log("row: ", row);
      let obj = {
        deviceId: row.deviceId,
      };
      this.goPages("/loggerDetail", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },
    addShipment() {
      // this.$refs.addOrganizationRef.showDialog();
      this.goPages("/createShipment");
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },

    getShipmentList() {
      let params = {
        timezone: this.timezone,
        status: this.activeName == "all" ? "" : this.activeName,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        // keyword: "", //关键字，过滤弹窗的$t('search_name') 的值
        // userId: "string",//不一定为前端需要传输的参数字段
        sortList: [
          {
            name: "",
            sort: "",
          },
        ],
        userId: "",
        orgIdList: [],
        alarmInd: 0,
        type: 0,
        startDate: "",
        endDate: "",
        // startTime: 0,
        // endTime: 0,
      };
      getShipmentListApi(params).then((r) => {
        console.log("=====", r);
        this.tableData = r.data.records || [];
        this.total = r.data.total;
        switch (this.activeName) {
          case "1":
            this.ongoingCount = this.total;
            break;
          case "0":
            this.notStartCount = this.total;
            break;
          case "9":
            this.endedCount = this.total;
            break;
          case "all":
            this.allCount = this.total;
            break;
        }
      });
    },
    pre() {},
    next() {},
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      // this.queryParams.page.pageIndex = val;
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getShipmentList();
    },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    //   this.rows = val;
    //   this.$nextTick(() => {
    //     this.loadData();
    //   });
    // },
    goShipmentDetail(row) {
      console.log("row: ", JSON.stringify(row));
      // let obj = {
      //   orgId: row.orgId,
      //   orgName: row.name,
      //   note: row.note,
      //   code: row.invitationCode,
      // };
      this.goPages("/shipmentDetail", {
        data: encodeURIComponent(JSON.stringify(row)),
      });
    },

    getGroupByStatusList() {
      getGroupByStatusListApi()
        .then((r) => {
          console.log("getGroupByStatusListApi=====", r);
          let data = r.data;
          const counts = data.reduce(
            (acc, item) => {
              switch (item.status) {
                case 0:
                  acc.notStartCount += item.num;
                  break;
                case 1:
                  acc.ongoingCount += item.num;
                  break;
                case 9:
                  acc.endedCount += item.num;
                  break;
                default:
                  break;
              }
              return acc;
            },
            {
              notStartCount: 0,
              ongoingCount: 0,
              endedCount: 0,
            }
          );
          this.ongoingCount = counts.ongoingCount;
          this.notStartCount = counts.notStartCount;
          this.endedCount = counts.endedCount;
          this.allCount =
            this.ongoingCount + this.notStartCount + this.endedCount;
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },

    deleteShipment() {
      // if (this.shipmentSelection.length > 1) {
      //   this.$message.error(this.$t("each_time_delete_one_organization"));
      //   return;
      // }
      let temp = [];
      this.shipmentSelection.forEach((element) => {
        temp.push(element.shipmentId);
      });

      this.$alert(this.$t("delete_shipment_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,

        type: "warning",
      }).then(() => {
        let params = {
          shipmentIds: temp,
        };
        console.log("params: ", params);

        deleteShipmentApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("delete_successful"));
          this.getShipmentList();
        });
      });
    },
    saveAsTemplate() {
      let temp = [];
      this.shipmentSelection.forEach((element) => {
        temp.push(element.shipmentId);
      });
      let params = {
        shipmentIds: temp,
      };
      console.log("params: ", params);
      saveShipmentTemplateApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        this.getShipmentList();
      });
    },
    handleShipmentSelectionChange(val) {
      console.log("val: ", val);
      this.shipmentSelection = val;
    },
    cancel() {
      this.shipmentSelection = [];
      this.getShipmentList();
    },
    shouldShowPopover(text, length) {
      // 如果文本长度超过16个字符则返回true
      return text && text.length > length;
    },
  },
  props: {},
  components: {
    //  AddOrganizationsDialog,
    //   AddOrganFilterDialog
  },
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
      this.getShipmentList();
    },
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.timezone = this.selectedTimeZone;
    this.activeName = this.$route.query.status || "1";
    this.getGroupByStatusList();
    this.getShipmentList();

    // this.getSensorList();
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  padding: 0 68px 0 38px;
  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }
  .btn-layout {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .title {
      font-size: 28px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      align-items: center;
      // margin-bottom: 10px;
    }
  }
  .table-layout {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddfe6;
    margin-top: -10px;
    border-radius: 8px;
    background: white;
    padding-bottom: 20px;

    .filter-header {
      padding: 0 20px;
      background: white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 60px;
      line-height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .search-style {
        width: 500px;
        .el-input .el-input--mini .el-input-group .el-input-group--append {
          height: 40px;
        }
        /deep/ .el-input__inner {
          background: white;
        }
      }
      .filter-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20px;
        line-height: 20px;
        img {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
        .btn {
          height: 20px;
          line-height: 20px;
          color: #1a72f4;
          cursor: pointer;
        }
      }
    }
    .pagination-box {
      border-top: 1px solid #dddfe6;
      border-bottom: 1px solid #dddfe6;
      padding: 0 20px;
      background: white;
      height: 60px;
      line-height: 60px;

      display: flex;
      flex-direction: row;
      align-items: center;

      .pagination {
        margin-top: 30px;
      }

      img {
        margin: 0 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.organ-detail {
  color: #1a72f4;
  text-decoration: underline;
  cursor: pointer;
  text-align: left;
}
.filter-box {
  display: flex;
  height: 60px;
  flex-direction: row;
  align-items: center;
  margin: 0 20px;
  .filter-item {
    height: 26px;
    margin-right: 16px;
    line-height: 26px;
    padding: 0 10px;
    display: flex;
    background: #e6e6e6;
    font-size: 12px;
    border-radius: 16px;
    flex-direction: row;
    color: #101010;
    align-items: center;
    img {
      width: 16px;
      margin-left: 5px;
      height: 16px;
    }
  }
}
.btn-style {
  background: #1a72f4;
  padding: 10px;

  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  .tip {
    color: white;
  }
}

/deep/.custom-tab-class .el-tabs__nav-scroll {
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 10px;
  padding-left: 50px;
}
.status-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .point {
    width: 6px;
    height: 6px;

    border-radius: 3px;
    margin-right: 4px;
  }
}

.alert-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  img {
    margin: 0 5px;
  }
}
.device-detail {
  color: #409eff;
  cursor: pointer;
}
/deep/.el-tabs__item {
  font-size: 18px;
}
</style>
