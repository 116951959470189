<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-24 09:09:17
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-12-28 23:42:30
 * @Description  : 修改设备名称
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("rename") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="addForm"
        status-icon
        ref="resetRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label-width="0px">
          <el-input
            clearable
            v-model="addForm.name"
            autocomplete="off"
            :placeholder="$t('please_enter')"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { modifyDetailNameApi } from "@/api/api.js";

export default {
  name: "",
  data() {
    return {
      addForm: {
        name: "",
      },
      dialogFormVisible: false,
      deviceId: "",
    };
  },
  methods: {
    showDialog(id,name) {
      this.dialogFormVisible = true;
      this.deviceId = id;
      this.addForm.name = name
    },
    handelOk() {
      let params = {
        ...this.addForm,
        deviceId: this.deviceId,
        remark: "",
        timezone: "",
      };
      if (params.name === "") {
        this.$message.error(this.$t("logger_name_cannot_be_empty"));
        return;
      }
      modifyDetailNameApi(params).then((r) => {
        console.log("=====", r);

        this.$message.success(this.$t("modify_success"));

        this.dialogFormVisible = false;
        this.$emit("refresh");
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less"></style>
