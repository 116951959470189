<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-16 17:19:38
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-05 19:34:59
 * @Description  : 组织列表
-->
<template>
  <div class="container">
    <div class="btn-layout">
      <div class="title font-heavy">{{ $t("organization") }}</div>

      <el-button
        type="primary"
        @click="addOrganization"
        style="width:165px;height:41px"
        >+ {{ $t("new_organization") }}</el-button
      >
    </div>
    <div class="table-layout">
      <div class="filter-header">
        <el-input
          v-model="search"
          size="mini"
          disabled
          :placeholder="$t('search_name')"
          class="search-style"
        >
          <template #append>
            <div class="filter-btn">
              <img src="../../assets/ic_filter.png" alt="" />
              <div @click="filterBtn" class="btn">
                {{ $t("add_filters") }}
              </div>
            </div>
          </template>
        </el-input>
        <!-- <div class="filter-btn">
          <img src="../../assets/ic_expand_down.png" alt="" />
          <div @click="selectColumnsBtn" class="btn">
            Columns
          </div>
        </div>  -->
      </div>

      <div class="filter-box" v-if="organFilterField.length > 0">
        <div
          class="filter-item"
          v-for="(item, index) in organFilterField"
          :key="index"
        >
          {{ item.name }} {{ item | formattedMethod }}
          {{ item | formattedValue }}
          <img
            @click="deleteFilter(index)"
            src="@/assets/ic_delete.png"
            alt=""
          />
        </div>
      </div>

      <!-- <div class="pagination-box" v-show="tableData.length > 0">
        <span>Showing -</span>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="rows"
            layout=" prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
        <span> items</span>
      </div> -->

      <!-- <div class="pagination-box">
        <div>
          {{ $t("Showing") }} -
          <span v-if="this.total > 10">10</span>
          <span v-if="this.total <= 10">{{ this.total }}</span> of
          {{ this.total }} items
        </div>
        <img
          src="../../assets/ic_arrow_left.png"
          alt=""
          srcset=""
          @click="pre"
        />
        <img
          src="../../assets/ic_arrow_right.png"
          alt=""
          srcset=""
          @click="next"
        />
      </div> -->
      <el-row class="btn-style" v-if="organSelection.length > 0">
        <el-col :span="8"></el-col>
        <el-col class="tip" :span="8"
          >{{ $t("already_selected") }} {{ this.organSelection.length }}
          {{ $t("number_of_organs") }}</el-col
        >
        <el-col :span="3" :offset="5">
          <el-button @click="deleteOrgan()" style="color:#1A72F4;width:145px">{{
            $t("delete")
          }}</el-button>
        </el-col>

        <!-- <el-button @click="toggleLoggerSelection()">取消选择</el-button> -->
      </el-row>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#f6f6fa',
          textAlign: 'center',
          color: 'black',
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleOrganSelectionChange"
        :empty-text="$t('no_data')"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column :label="$t('organ_name')" prop="name">
          <template v-slot:default="scope">
            <span class="organ-detail " @click="goOrganDetail(scope.row)">
              {{ scope.row.name }}</span
            >
          </template>
        </el-table-column>

        <el-table-column :label="$t('organ_code')" prop="invitationCode">
        </el-table-column>
        <el-table-column
          :label="$t('number_of_organization_members')"
          prop="memberNum"
        >
        </el-table-column>

        <el-table-column :label="$t('total_loggers')" prop="totalNum">
        </el-table-column>

        <el-table-column :label="$t('loggers_with_alerts')" prop="alertNum">
        </el-table-column>

        <!-- 编辑或查看权限，自己创建的默认编辑权限，组织内的看赋值给组织的权限，分享的看分享权限 0:查看 1：编辑  默认值:0 -->
        <!-- <el-table-column :label="$t('limits')" prop="limits">
          <template v-slot:default="scope">
            <div v-if="scope.row.limits == 0">{{ $t("read_only") }}</div>
            <div v-else-if="scope.row.limits == 1">{{ $t("edit") }}</div>
          </template>
        </el-table-column> -->
        <!-- 告警数 -->
        <!-- <el-table-column
          :label="$t('records_with_alerts_total')"
          prop="alarmNum"
          width="200px"
        >
        </el-table-column> -->
        <el-table-column :label="$t('note')" prop="note" min-width="150">
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top:10px;text-align:center"
        background
        @current-change="handleCurrentChange"
        :current-page="Number(this.pageNum)"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="Number(this.pageSize)"
        layout="total,pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <AddOrganizationsDialog
      ref="addOrganizationRef"
      @ok="getOrgList"
    ></AddOrganizationsDialog>
    <AddOrganFilterDialog
      ref="filterRef"
      @filters="getOrgList"
    ></AddOrganFilterDialog>
  </div>
</template>

<script>
import AddOrganizationsDialog from "./modal/AddOrganizationsDialog.vue";
import AddOrganFilterDialog from "./modal/AddOrganFilterDialog.vue";
import { orgListApi, orgDelApi } from "@/api/api.js";

export default {
  name: "Organ",
  data() {
    return {
      tableData: [],
      organSelection: [],
      search: "",
      total: 0,
      // rows: 30,
      orgId: "",
      pageSize: 10,
      pageNum: 1,
      timezone: 0,
      organFilterField: [],
    };
  },
  methods: {
    addOrganization() {
      this.$refs.addOrganizationRef.showDialog();
    },

    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    deleteFilter(index) {
      console.log("index: ", index);
      console.log("this.organFilterField: ", this.organFilterField);
      console.log("this.filters: ", this.filters);

      // 检查 this.organFilterField 是否存在且是一个数组
      if (this.organFilterField && Array.isArray(this.organFilterField)) {
        // 获取要删除的 filter 的 field
        const fieldToDelete = this.organFilterField[index].field;

        // 检查 this.filters 是否存在且是一个数组
        if (this.filters && Array.isArray(this.filters)) {
          // 从 filters 数组中移除具有相同 field 的 filter
          this.filters = this.filters.filter(
            (filter) => filter.field !== fieldToDelete
          );

          // 从 organFilterField 数组中移除当前项
          this.organFilterField.splice(index, 1);

          console.log("this.filters: ", this.filters);

          // 调用 getOrgList 方法更新场景列表
          this.getOrgList(this.filters);
        } else {
          console.error("filters为空");
        }
      } else {
        console.error("sceneFilterField为空.");
      }
    },
    filterBtn() {
      this.$refs.filterRef.showDialog();
    },
    getOrgList(filters) {
      console.log("filters: ", filters);
      this.filters = filters;
      let params = {
        timezone: this.timezone,
        filters: this.filters,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        keyword: "", //关键字，过滤弹窗的$t('search_name') 的值
        // userId: "string",//不一定为前端需要传输的参数字段
      };
      orgListApi(params).then((r) => {
        console.log("=====", r);
        this.tableData = r.data.records || [];
        this.total = r.data.total;
        this.organFilterField = filters || [];
      });
    },
    pre() {},
    next() {},
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      // this.queryParams.page.pageIndex = val;
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getOrgList();
    },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    //   this.rows = val;
    //   this.$nextTick(() => {
    //     this.loadData();
    //   });
    // },
    goOrganDetail(row) {
      let obj = {
        orgId: row.orgId,
        orgName: row.name,
        note: row.note,
        code: row.invitationCode,
      };
      this.goPages("/organDetail", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },

    deleteOrgan() {
      if (this.organSelection.length > 1) {
        this.$message.error(this.$t("each_time_delete_one_organization"));
        return;
      }
      let temp = [];
      this.organSelection.forEach((element) => {
        temp.push(element.orgId);
      });

      this.$alert(this.$t("delete_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,

        type: "warning",
      }).then(() => {
        let params = {
          orgId: temp[0],
          name: "",
          owner: "",
          remark: "",
        };
        console.log("params: ", params);

        orgDelApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("delete_successful"));
          this.getOrgList();
        });
      });
    },

    handleOrganSelectionChange(val) {
      console.log("val: ", val);
      this.organSelection = val;
    },
  },
  props: {},
  components: { AddOrganizationsDialog, AddOrganFilterDialog },
  computed: {
    selectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    selectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
      this.getOrgList();
    },
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.timezone = this.selectedTimeZone;

    this.getOrgList();
    // this.getSensorList();
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  padding: 0 68px 0 38px;
  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }
  .btn-layout {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .title {
      font-size: 28px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      align-items: center;
      // margin-bottom: 10px;
    }
  }
  .table-layout {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddfe6;
    margin-top: -10px;
    border-radius: 8px;

    .filter-header {
      padding: 0 20px;
      background: white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 60px;
      line-height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .search-style {
        width: 500px;
        .el-input .el-input--mini .el-input-group .el-input-group--append {
          height: 40px;
        }
        /deep/ .el-input__inner {
          background: white;
        }
      }
      .filter-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20px;
        line-height: 20px;
        img {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
        .btn {
          height: 20px;
          line-height: 20px;
          color: #1a72f4;
          cursor: pointer;
        }
      }
    }
    .pagination-box {
      border-top: 1px solid #dddfe6;
      border-bottom: 1px solid #dddfe6;
      padding: 0 20px;
      background: white;
      height: 60px;
      line-height: 60px;

      display: flex;
      flex-direction: row;
      align-items: center;

      .pagination {
        margin-top: 30px;
      }

      img {
        margin: 0 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.organ-detail {
  color: #1a72f4;
  text-decoration: underline;
  cursor: pointer;
}
.filter-box {
  display: flex;
  height: 60px;
  flex-direction: row;
  align-items: center;
  margin: 0 20px;
  .filter-item {
    height: 26px;
    margin-right: 16px;
    line-height: 26px;
    padding: 0 10px;
    display: flex;
    background: #e6e6e6;
    font-size: 12px;
    border-radius: 16px;
    flex-direction: row;
    color: #101010;
    align-items: center;
    img {
      width: 16px;
      margin-left: 5px;
      height: 16px;
    }
  }
}
.btn-style {
  background: #1a72f4;
  padding: 10px;

  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  .tip {
    color: white;
  }
}
</style>
