<!--
 * @Author       : JiangChao
 * @Date         : 2025-01-03 09:25:10
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-03 16:41:13
 * @Description  : 首页汇总组件
-->
<template>
  <div class="card-style">
    <div class="row-style">
      <div class="c-p" @click="goLogger(1)">
        <div class="sub-style">
          <span class="title font-heavy">{{ $t("total_loggers") }}</span>
          <span class="value font-heavy">{{ summaryData.totalNum }}</span>
        </div>
      </div>
      <div class="c-p" @click="goLogger(2)">
        <div class="sub-style">
          <span class="title font-heavy">{{ $t("loggers_started") }}</span>
          <span class="value font-heavy">{{ summaryData.startNum }}</span>
        </div>
      </div>

      <div class="c-p border-right" @click="goLogger(3)">
        <div class="sub-style">
          <span class="title font-heavy">{{ $t("loggers_ended") }}</span>
          <span class="value font-heavy">{{ summaryData.endedNum }}</span>
        </div>
      </div>
      <div class="c-p" @click="goLogger(4)">
        <div class="sub-style">
          <span class="title font-heavy">{{ $t("loggers_with_alerts") }}</span>
          <div class="img-style">
            <img
              :src="
                summaryData.alertNum == 0 ? withoutAlertIcon : withAlertIcon
              "
              width="18"
              height="18"
            />
            <span class="value font-heavy">{{ summaryData.alertNum }}</span>
          </div>
        </div>
      </div>

      <div class="c-p border-right" @click="goLogger(5)">
        <div class="sub-style">
          <span class="title font-heavy">{{ $t("unchecked_alerts") }}</span>
          <div class="img-style">
            <img
              :src="
                summaryData.uncheckedAlertNum == 0
                  ? withoutAlertIcon
                  : withAlertIcon
              "
              width="18"
              height="18"
            />
            <span class="value font-heavy">{{
              summaryData.uncheckedAlertNum
            }}</span>
          </div>
        </div>
      </div>

      <div class="c-p" @click="goShipment('1')">
        <div class="sub-style">
          <span class="title font-heavy">{{ $t("ongoing_shipments") }}</span>
          <div class="img-style">
            <span class="value font-heavy">{{
              summaryData.ongoingShipment
            }}</span>
          </div>
        </div>
      </div>

      <div class="c-p border-right" @click="goShipment('9')">
        <div class="sub-style">
          <span class="title font-heavy">{{ $t("ended_shipments") }}</span>
          <div class="img-style">
            <span class="value font-heavy">{{
              summaryData.endedShipment
            }}</span>
          </div>
        </div>
      </div>

      <!-- <div
          class="c-p border-right"
          style="text-align:center"
          @click="goScene"
        >
          <div class="sub-style">
            <span class="title font-heavy">{{
              $t("total_number_of_scene")
            }}</span>
            <span class="value font-heavy">{{ summaryData.sceneNum }}</span>
          </div>
        </div> -->
      <div class="c-p" @click="goOrgan">
        <div class="sub-style">
          <span class="title font-heavy">{{ $t("my_teams") }}</span>
          <span class="value font-heavy">{{ summaryData.orgNum }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryData",
  data() {
    return {
      withoutAlertIcon: require("@/assets/ic_no_alert.png"),
      withAlertIcon: require("@/assets/ic_alert.png"),
    };
  },
  methods: {
    // 看板页面跳转时携带的参数 1:Logger started 3:logger ended, 11:logger with alert
    // 看板页面跳转时携带的参数 1:全部设备 2:已开始记录的设备 3:已停止的设备 4:报警设备 5:未确认告警设备 6:运单中的设备 7:不在运单中的设备
    goLogger(status) {
      this.$store.dispatch("updateSelectedMenuItem", 3);
      localStorage.setItem("selectedMenuItem", 3);
      this.goPages("/home/logger", {
        dashboardStatus: status,
      });
    },

    goShipment(status) {
      this.$store.dispatch("updateSelectedMenuItem", 4);
      localStorage.setItem("selectedMenuItem", 4);
      this.goPages("/home/shipment", {
        status: status,
      });
    },

    goOrgan() {
      this.$store.dispatch("updateSelectedMenuItem", 5);
      localStorage.setItem("selectedMenuItem", 5);
      this.goPages("/home/organ");
    },
  },
  props: {
    summaryData: {
      type: Object,
      default: () => ({
        totalNum: 0,
        startNum: 0,
        endedNum: 0,
        alertNum: 0,
        uncheckedAlertNum: 0,
        ongoingShipment: 0,
        endedShipment: 0,
        sceneNum: 0,
        orgNum: 0,
      }),
    },
  },
  components: {},
  computed: {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">
.card-style {
  height: 107px;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .row-style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // padding-top: 2px;
    width: 100%;
    .border-right {
      border-right: 1px solid #bbbbbb;
    }
    .center {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    // /deep/.el-statistic {
    // padding: 5px;
    // .prefix {
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    // }
    // .title {
    //   font-size: 12px;
    //   color: #1a72f4;
    //   margin: 5px;
    // }
    // .number {
    //   font-size: 18px;
    //   margin: 5px;
    //   font-weight: 500;
    // }
    // }
    .c-p {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 16.6%;
      .title {
        font-size: 12px;
        color: #1a72f4;
      }
      .value {
        font-size: 30px;
        margin-top: 13px;
        color: #262626;
        font-weight: 500;
      }
      .sub-style {
        display: flex;
        flex-direction: column;
        // justify-content: flex-start;
        align-items: center;
      }
      .img-style {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          margin-top: 13px;
          margin-left: -23px;
          padding-right: 5px;
        }
      }
    }
    // .title {
    //   color: #848484 100%;
    //   font-size: 12px;
    // }
    // /deep/ .custom-statistic {
    //   /deep/ .custom-statistic .el-statistic__value {
    //     font-size: 30px;
    //   }
    // }
  }
}
</style>
