<!--
 * @Author       : JiangChao
 * @Date         : 2025-01-02 16:57:48
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-16 09:44:23
 * @Description  : 运单详情页的数据统计
-->
<template>
  <div class="summary-box">
    <span class="font-heavy">{{ $t("data_summary") }}</span>
    <el-table
      :data="totalList"
      style="width: 100%"
      border
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      :cell-style="{ 'text-align': 'center' }"
      :empty-text="$t('no_data')"
      :span-method="objectSpanMethod"
    >
      <!-- Logger 列 -->
      <el-table-column :label="$t('logger')" width="140">
        <template slot-scope="scope">
          <div
            class="logger-detail font-heavy"
            style="text-align: left"
            @click="goDeviceDetail(scope.row.deviceInfo)"
          >
            {{ scope.row.deviceInfo.deviceId }}<br />
            #{{ scope.row.deviceInfo.name }}
          </div>
        </template>
      </el-table-column>

      <!-- 温度最小值 -->
      <el-table-column prop="tempMin" :label="$t('mins')">
        <template slot-scope="scope">
          {{ scope.row.dataTotal.tempMin || "-" }}<br />
          {{ scope.row.dataTotal.wetnessMin || "-" }}<br />
        </template>
      </el-table-column>

      <!-- 温度最大值 -->
      <el-table-column prop="tempMax" :label="$t('maxes')">
        <template slot-scope="scope">
          {{ scope.row.dataTotal.tempMax || "-" }}<br />
          {{ scope.row.dataTotal.wetnessMax || "-" }}<br />
        </template>
      </el-table-column>

      <!-- 温度平均值 -->
      <el-table-column prop="tempAvg" :label="$t('averages')">
        <template slot-scope="scope">
          {{ scope.row.dataTotal.tempAvg || "-" }}<br />
          {{ scope.row.dataTotal.wetnessAvg || "-" }}<br />
        </template>
      </el-table-column>

      <!-- MKT -->
      <el-table-column prop="totalList.dataTotal.tempMkt" :label="$t('D_Mkt')">
        <template slot-scope="scope">
          {{ scope.row.dataTotal.tempMkt || "-" }}<br />
        </template>
      </el-table-column>

      <!-- 总数 -->
      <el-table-column
        prop="totalList.dataTotal.total"
        :label="$t('number_of_records')"
      >
        <template slot-scope="scope">
          {{ scope.row.dataTotal.total || "-" }}<br />
        </template>
      </el-table-column>

      <!-- 警报总数 -->
      <el-table-column
        prop="totalList.dataTotal.alarmTotal"
        :label="$t('total_number_of_alarms')"
      >
        <template slot-scope="scope">
          {{ scope.row.dataTotal.alarmTotal || "-" }}<br />
        </template>
      </el-table-column>
      <!-- 合并单元格显示 duration -->
      <el-table-column :label="$t('duration')" width="180">
        <template slot-scope="scope">
          <span v-if="scope.$index === 0">{{ duration || "-" }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "DataSummary",
  data() {
    return {};
  },
  methods: {
    goDeviceDetail(row) {
      localStorage.setItem("selectedMenuItem", 3);
      this.$store.dispatch("updateSelectedMenuItem", 3);
      let obj = {
        deviceId: row.deviceId,
      };
      this.goPages("/loggerDetail", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(`column: ${column}, row: ${row}`);
      if (columnIndex === 7) {
        // 假设 Duration 列是最后一列
        if (rowIndex === 0) {
          return {
            rowspan: this.totalList.length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  },
  props: {
    totalList: {
      type: Array,
      default: () => [],
    },
    duration: {
      type: String,
      default: "",
    },
  },
  components: {},
  computed: {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">
.summary-box {
  background: white;
  padding: 20px;
  border-radius: 8px;
  .logger-detail {
    color: #409eff;
    cursor: pointer;
  }
  span {
    font-size: 28px;
    font-weight: bold;
  }

  table {
    width: 100%;
    margin-bottom: -5px;
    margin: 20px 0;
  }
  .white-cell {
    background: white;
    width: 33.33%;
    padding: 10px;
    border: 1px solid #bbbbbb;
    text-align: center;
  }
}
</style>
