<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-18 21:42:08
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-15 10:06:15
 * @Description  : 添加设备
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="51%"
    :append-to-body="true"
    :close-on-click-modal="false" 
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("add_logger") }}
      </div>
    </template>
    <div class="tip-style">{{ $t("add_logger_dialog_tip") }}</div>
    <el-form :model="addForm" status-icon ref="resetRef" label-width="100px">
      <el-form-item label-width="0px">
        <el-input
          clearable
          v-model="addForm.deviceId"
          autocomplete="off"
          :placeholder="$t('add_logger_tip')"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="container">
      <img
        src="../../../assets/ic_example.png"
        width="400"
        height="424"
        alt=""
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addByDeviceIdApi } from "@/api/api.js";

export default {
  name: "",
  data() {
    return {
      addForm: {
        deviceId: "",
      },
      dialogFormVisible: false,
    };
  },
  methods: {
    // show() {
    //   this.showDialog();
    // },
    showDialog() {
      this.dialogFormVisible = true;
      this.addForm.deviceId = "";
    },

    handelOk() {
      if (!this.addForm.deviceId) {
        this.$message.error(this.$t("IMEI_can_not_be_empty"));
        return;
      }

      let params = {
        ...this.addForm,
        model: "",
        serverType: "",
        port: "",
      };
      addByDeviceIdApi(params).then((r) => {
        console.log("=====", r);

        this.$message.success(this.$t("add_logger_success"));

        this.dialogFormVisible = false;
        this.$emit("refresh");
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tip-style {
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-break: break-word;
}
</style>
