<!--
 * @Author       : JiangChao
 * @Date         : 2025-01-08 13:27:20
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-08 13:35:24
 * @Description  : 设备详情页-统计数据
-->
<template>
  <div class="statistics-box">
    <span class="font-heavy">{{ $t("statistical_data") }}</span>
    <table border="0" cellspacing="0" style="border-collapse: collapse">
      <tr>
        <td class="white-cell">
          <div></div>
        </td>
        <td class="white-cell font-heavy">
          <div>{{ $t("mins") }}</div>
        </td>
        <td class="white-cell font-heavy">
          <div>{{ $t("maxes") }}</div>
        </td>
        <td class="white-cell font-heavy">
          <div>{{ $t("averages") }}</div>
        </td>
      </tr>
      <tr v-for="(item, index) in totalList" :key="index">
        <td class="white-cell font-heavy">
          <div v-if="item.name == 'Temperature'">{{ $t("temperature") }}</div>
          <div v-else-if="(item.name = 'Wetness')">{{ $t("humidity") }}</div>
        </td>
        <td class="white-cell">
          <div v-if="index == 0">
            {{ Number(item.min) | convertTemperature(setSelectedTempType)
            }}{{ setSelectedTempType }}
          </div>
          <div v-else-if="index == 1">{{ item.min }}</div>
        </td>
        <td class="white-cell">
          <div v-if="index == 0">
            {{ Number(item.max) | convertTemperature(setSelectedTempType)
            }}{{ setSelectedTempType }}
          </div>
          <div v-else-if="index == 1">{{ item.max }}</div>
        </td>
        <td class="white-cell">
          <div v-if="index == 0">
            {{ Number(item.avg) | convertTemperature(setSelectedTempType)
            }}{{ setSelectedTempType }}
          </div>
          <div v-else-if="index == 1">{{ item.avg }}</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "StatisticsBox",
  data() {
    return {};
  },
  methods: {},
  props: {
    totalList: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  computed: {
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">
.statistics-box {
  margin: 20px 0;
  padding: 20px;
  background: white;
  border-radius: 8px;

  span {
    font-size: 28px;
    font-weight: 500;
  }
  table {
    width: 100%;
    margin-bottom: -5px;
    margin: 20px 0;
  }
  .white-cell {
    background: white;
    width: 25%;
    padding: 10px;
    border: 1px solid #bbbbbb;
    text-align: center;
  }
}
</style>
