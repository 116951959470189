<!--
 * @Author       : JiangChao
 * @Date         : 2025-01-08 13:32:28
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-08 13:34:53
 * @Description  : 设备详情页概要
-->
<template>
  <div class="summary-box">
    <span class="font-heavy">{{ $t("summary") }}</span>
    <table border="0" cellspacing="0" style="border-collapse: collapse">
      <tr>
        <td class="white-cell">
          <div class="font-heavy">
            {{ $t("duration_of_recording") }}
          </div>
          <div>{{ summaryDuration || "-" }}</div>
        </td>
        <td class="white-cell">
          <div class="font-heavy">{{ $t("number_of_records") }}</div>
          <div>{{ summaryRecordTotal }}</div>
        </td>
        <td class="white-cell">
          <div class="font-heavy">{{ $t("total_number_of_alarms") }}</div>

          <div>{{ summaryAlarmTotal }}</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "SummaryBox",
  data() {
    return {};
  },
  methods: {},
  props: {
    summaryDuration: { type: String, default: "" },
    summaryRecordTotal: { type: Number, default: 0 },
    summaryAlarmTotal: { type: Number, default: 0 },
  },
  components: {},
  computed: {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">
.summary-box {
  background: white;
  padding: 20px;
  border-radius: 8px;

  span {
    font-size: 28px;
    font-weight: 500;
  }

  table {
    width: 100%;
    margin-bottom: -5px;
    margin: 20px 0;
  }
  .white-cell {
    background: white;
    width: 33.33%;
    padding: 10px;
    border: 1px solid #bbbbbb;
    text-align: center;
  }
}
</style>
