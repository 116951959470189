<!--
 * @Author       : JiangChao
 * @Date         : 2024-07-20 20:33:44
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-05 19:45:53
 * @Description  : 筛选弹窗
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
   <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("add_filters") }}
      </div>
    </template>
    <div>{{ $t("add_filter_tip") }}</div>
    <el-row>
      <el-col :span="16"></el-col>
    </el-row>

    <el-form ref="form" :model="form" :rules="formRules" label-width="120px">
      <!-- <el-input
        v-model="form.keyword"
        style="margin:5px 0"
        :placeholder="$t('search_name')"
      ></el-input> -->
      <el-form-item
        v-for="(rule, index) in form.filters"
        :key="rule.key"
        :prop="'filters.' + index + '.value'"
      >
        <div class="rule-box">
          <div class="selects-container">
            <el-select
              v-model="rule.field"
              class="select-item"
              @change="onFieldChange(rule, $event)"
            >
              <el-option
                :label="item.name"
                :value="item.value"
                v-for="item in sceneFilterField"
                :key="item.value"
              ></el-option>
            </el-select>
            <el-select v-model="rule.method" class="select-item">
              <el-option
                :label="item.name"
                :value="item.value"
                v-for="item in sceneFilterConditions"
                :key="item.value"
              ></el-option>
            </el-select>

            <div style="flex:1">
              <el-input
                style="flex:1"
                v-model="rule.value"
                v-if="rule.remark == '0'"
              >
              </el-input>
              <el-input style="flex:1;display:none" v-model="rule.name">
              </el-input>
              <el-select
                v-model="rule.value"
                class="select-item"
                v-if="rule.remark == '2'"
              >
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in filterValues"
                  :key="item.value"
                ></el-option>
              </el-select>

              <el-date-picker
                v-if="rule.remark == '1'"
                class="no-border"
                v-model="rule.value"
                type="date"
                value-format="yyyy-MM-dd"
                :placeholder="$t('date')"
                @change="onChange"
              >
              </el-date-picker>
            </div>
          </div>
          <!-- <img
            @click.prevent="removeRule(rule)"
            src="../../../assets/ic_cross.png"
            alt=""
          /> -->
        </div>
      </el-form-item>
    </el-form>
    <div class="filter " @click="addRule">
      <img src="../../../assets/ic_add.png" alt="" />
      <span>{{ $t("add_filters") }}</span>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false" plain>{{
        $t("cancel")
      }}</el-button>
      <div style="flex:1">
        <!-- <el-button @click="saveFilterSet" plain>{{
          $t("save_filter_set")
        }}</el-button> -->
        <el-button type="primary" @click="applyChanges">{{
          $t("apply_change")
        }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { selectApi } from "@/api/api.js";
// import moment from "moment";

export default {
  name: "AddAlertFilterDialog",
  data() {
    return {
      form: {
        filters: [], //配置详情
      },
      dialogFormVisible: false,
      deviceId: "",
      sceneFilterField: [],
      sceneFilterConditions: [],
      formRules: {
        name: [
          {
            required: true,
            message: this.$t("please_enter_scene_name"),
            trigger: "blur",
          },
        ],
      },
      filterValues: [],
    };
  },
  methods: {
    showDialog(id) {
      this.dialogFormVisible = true;
      this.deviceId = id;
      this.form.filters = [];
    },
    getSceneFilterField() {
      selectApi({ type: "SceneFilterField" }).then((r) => {
        this.sceneFilterField = r.data;
      });
    },
    getSceneFilterConditions(code) {
      selectApi({ type: "FilterConditions", pCode: code }).then((r) => {
        this.sceneFilterConditions = r.data;
      });
    },
    getFilterValues(code) {
      selectApi({ type: "FilterValues", pCode: code }).then((r) => {
        this.filterValues = r.data;
      });
    },
    applyChanges() {
      this.$message.success(this.$t("operation_successful"));
      this.dialogFormVisible = false;
      this.$emit("filters", this.form.filters);
    },
    onChange(val) {
      console.log("val: ", val);
      // this.form.value = moment(val).format("YYYY-MM-DD");
      // console.log("this.form.value : ", this.form.value);
    },
    addRule() {
      this.form.filters.push({
        value: "",
        field: "",
        method: "",
        name: "",
        remark: "",
        key: Date.now(),
      });
    },
    onFieldChange(rule, value) {
      const selectedItem = this.sceneFilterField.find(
        (item) => item.value === value
      );
      if (selectedItem) {
        console.log("selectedItem: ", selectedItem);
        rule.name = selectedItem.name;
        rule.remark = selectedItem.remark; // 添加 remark 到 rule 对象
        this.getSceneFilterConditions(selectedItem.code); // 根据 code 获取 conditions
        this.getFilterValues(selectedItem.code);
      }
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;

    this.getSceneFilterField();
    // this.getSceneFilterConditions();
  },
  created() {},
};
</script>

<style scoped lang="less">
.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 19px;
  img {
    width: 25px;
  }
  span {
    font-size: 14px;
    margin-left: 6px;
    color: #1972f4;
  }
}
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rule-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid rgba(187, 187, 187, 1);
  // padding: 5px 10px;
  height: 49px;
  line-height: 49px;
  border-radius: 5px;
  margin-left: -120px;
  img {
    width: 33px;
    height: 33px;
  }
  .selects-container {
    display: flex;
    gap: 34px;
    align-items: center;
    .select-item {
      flex: 1;
      height: 47px;
      line-height: 47px;
    }
  }
}
</style>
