<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-16 17:18:59
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-07 09:21:57
 * @Description  : 设备列表
-->
<template>
  <div class="container">
    <div class="title font-heavy">{{ $t("logger") }}</div>
    <!-- <div class="btn-layout">
      <el-button type="primary" @click="addLoggers"
        >+{{ $t("new_loggers") }}</el-button
      >
    </div> -->
    <div class="table-layout">
      <div class="filter-header">
        <el-input
          v-model="search"
          size="mini"
          disabled
          :placeholder="$t('search_name')"
          class="search-style"
        >
          <template #append>
            <div class="filter-btn">
              <img src="@/assets/ic_filter.png" alt="" />
              <div @click="filterBtn" class="btn">
                {{ $t("add_filters") }}
              </div>
            </div>
          </template>
        </el-input>

        <!-- <div class="filter-btn">
          <img src="../../assets/ic_expand_down.png" alt="" />
          <div @click="selectColumnsBtn" class="btn">
            Columns
          </div>
        </div> -->
      </div>
      <div class="filter-box" v-if="deviceFilterField.length > 0">
        <div
          class="filter-item"
          v-for="(item, index) in deviceFilterField"
          :key="index"
        >
          {{ item.name }} {{ item | formattedMethod }}
          {{ item | formattedValue }}
          <img
            @click="deleteFilter(index)"
            src="@/assets/ic_delete.png"
            alt=""
          />
        </div>
      </div>
      <!-- <div class="pagination-box" v-show="tableData.length > 0">
        <span>Showing -</span>
        {{ this.total }}-
        <span> items</span>
      </div> -->
      <el-row class="btn-style" v-if="loggerSelection.length > 0">
        <el-col :span="8"></el-col>
        <el-col class="tip" :span="8"
          >{{ $t("already_selected") }} {{ this.loggerSelection.length }}
          {{ $t("number_of_devices") }}</el-col
        >
        <el-col :span="3" :offset="5">
          <el-button
            @click="deleteLogger()"
            style="color: #1a72f4; width: 145px"
            >{{ $t("delete") }}</el-button
          >
        </el-col>

        <!-- <el-button @click="toggleLoggerSelection()">取消选择</el-button> -->
      </el-row>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#f6f6fa',
          textAlign: 'center',
          color: 'black',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :empty-text="$t('no_data')"
        :fit="true"
        :row-style="getRowStyle"
        :default-sort="{ prop: 'refreshDate', order: 'descending' }"
        @sort-change="handleSortChange"
      >
        <!-- @selection-change="handleLoggerSelectionChange" -->

        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->

        <el-table-column
          :label="$t('device_id')"
          prop="deviceId"
          min-width="130"
        >
          <template v-slot:default="scope">
            <span
              class="logger-detail"
              @click="scope.row.status !== 9 ? goDeviceDetail(scope.row) : null"
              :style="{
                cursor: scope.row.status === 9 ? 'not-allowed' : 'pointer',
                color: scope.row.status === 9 ? 'rgb(192, 196, 204)' : '',
              }"
            >
              {{ scope.row.deviceId }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('device_name')" prop="name" width="170">
          <template v-slot:default="scope">
            <div style="text-align: left">
              <el-popover
                v-if="shouldShowPopover(scope.row.name, 16)"
                placement="top-start"
                title=""
                width="200"
                trigger="hover"
                :content="scope.row.name"
              >
                <span slot="reference">
                  {{ scope.row.name | ellipsisText(16) }}</span
                >
              </el-popover>
              <span v-else>
                {{ scope.row.name }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('device_status')"
          prop="status"
          min-width="90"
        >
          <template v-slot:default="scope">
            <span v-if="scope.row.status == '0'">{{ $t("not_started") }}</span>
            <span v-if="scope.row.status == '1'">{{ $t("recording") }}</span>
            <span v-if="scope.row.status == '2'">{{
              $t("delay_recording")
            }}</span>
            <span v-if="scope.row.status == '3'">{{ $t("ended") }}</span>
            <span v-if="scope.row.status == '9'">{{ $t("discard") }}</span>
            <span v-if="scope.row.status == '4'">{{ $t("stopped") }}</span>
          </template>
        </el-table-column>
        <!-- 告警状态 1：告警 0：未告警 (设备有过告警，就一直记录为告警) -->
        <el-table-column
          :label="$t('alert_status')"
          prop="alarmInd"
          width="110px"
        >
          <template v-slot:default="scope">
            <div v-if="scope.row.alarmInd == 1" class="alert-style">
              <img src="@/assets/ic_alert.png" width="15" /><span>{{
                scope.row.alarmNum
              }}</span>
            </div>
            <div v-else-if="scope.row.alarmInd == 0" class="alert-style">
              <img src="@/assets/ic_no_alert.png" width="15" /><span>{{
                $t("no_alert")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- temp温度 wetness湿度 power电量 refreshDate最后更新时间 -->
        <el-table-column
          :label="$t('latest_measurements')"
          min-width="290"
          prop="refreshDate"
          sortable
        >
          <template v-slot:default="scope">
            <div class="measurements-col">
              {{
                Number(scope.row.temp) | convertTemperature(setSelectedTempType)
              }}
              <span v-if="scope.row.temp">{{ setSelectedTempType }}</span>
              <span style="margin-left: 5px"> {{ scope.row.wetness }}</span>

              <!-- 0-20% 20-50% 50-90% 90-100% -->
              <!-- Check if battery is not null -->
              <template v-if="scope.row.battery">

                <BatteryInfo
                  v-if="scope.row.battery"
                  :value="scope.row.battery.value"
                ></BatteryInfo>
              </template>
              <!-- Display a message if battery is null -->
              <span style="margin-left: 5px">{{
                scope.row.refreshDate
                  | formatDateWithTimeZone(
                    setSelectedTimeZone,
                    setSelectedLanguage
                  )
              }}</span>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column :label="$t('address')" prop="address" width="150px"> -->
        <!-- </el-table-column> -->

        <el-table-column :label="$t('start_time')" prop="startTime" width="150">
          <template v-slot:default="scope">
            <!-- <div class="measurements-col"> -->

            {{
              scope.row.startTime
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
            }}
            <!-- </div> -->
          </template>
        </el-table-column>

        <el-table-column :label="$t('end_time')" prop="endTime" width="150">
          <template v-slot:default="scope">
            <!-- <div class="measurements-col"> -->

            {{
              scope.row.endTime
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
            }}
            <!-- </div> -->
          </template>
        </el-table-column>

        <!-- <el-table-column :label="$t('scene_id')" prop="name"> </el-table-column> -->
        <!-- <el-table-column :label="$t('records_total')" prop="name">
        </el-table-column> -->
        <!-- <el-table-column :label="$t('records_with_alerts_total')" prop="name">
        </el-table-column> -->
        <!-- <el-table-column :label="$t('owner')" prop="name"> </el-table-column> -->
        <!-- <el-table-column :label="$t('end_time ')" prop="name">
        </el-table-column> -->
        <!-- 告警数 -->
        <!-- <el-table-column
          :label="$t('records_with_alerts_total')"
          prop="alarmNum"
        >
        </el-table-column> -->
        <!-- 设备型号 -->
        <!-- <el-table-column :label="$t('model')" prop="model"> </el-table-column> -->
        <!-- 编辑或查看权限，自己创建的默认编辑权限，组织内的看赋值给组织的权限，分享的看分享权限 0:查看 1：编辑  默认值:0 -->
        <!-- <el-table-column :label="$t('limits')" prop="limits">
          <template v-slot:default="scope">
            <div v-if="scope.row.limits == 0">{{ $t("read_only") }}</div>
            <div v-else-if="scope.row.limits == 1">{{ $t("edit") }}</div>
          </template>
        </el-table-column> -->
        <!-- 组织详情信息，设备列表使用 -->
        <!-- <el-table-column
          :label="$t('affiliated_scene')"
          prop="sceneName"
          min-width="120"
        >
          <template v-slot:default="scope">
            <span> {{ scope.row.sceneName || "-" }}</span>
          </template>
        </el-table-column> -->

        <el-table-column
          :label="$t('in_a_shipment')"
          prop="shipmentName"
          width="150"
        >
          <template v-slot:default="scope">
            <div v-if="scope.row.shipmentName" class="more-style">
              <el-popover
                v-if="shouldShowPopover(scope.row.shipmentName, 4)"
                placement="top-start"
                title=""
                width="200"
                trigger="hover"
                :content="scope.row.shipmentName"
              >
                <span slot="reference">{{
                  scope.row.shipmentName | ellipsisText(4)
                }}</span>
              </el-popover>
              <span v-else>
                {{ scope.row.shipmentName }}
              </span>

              <el-button
                type="primary"
                class="more-style font-heavy"
                @click="goShipmentList(scope.row)"
                size="mini"
              >
                {{ $t("more") }}</el-button
              >
            </div>
            <div v-if="!scope.row.shipmentName">-</div>
          </template>
        </el-table-column>
        <!-- 纬度latitude 经度longitude -->
        <!-- {{ scope.row.longitude }} {{ scope.row.latitude }} -->

        <el-table-column :label="$t('latest_location')" prop="" min-width="240">
          <template v-slot:default="scope">
            <div class="location-col">
              <el-popover
                v-if="shouldShowPopover(generatePopoverContent(scope.row), 25)"
                placement="top-start"
                title=""
                width="200"
                trigger="hover"
                :content="generatePopoverContent(scope.row)"
              >
                <span slot="reference">
                  {{ generatePopoverContent(scope.row) | ellipsisText(25) }}
                </span>
              </el-popover>
              <span v-else @click="goShipmentDetail(scope.row)">
                {{ generatePopoverContent(scope.row) }}
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: center"
        background
        @current-change="handleCurrentChange"
        :current-page="Number(this.pageNum)"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="Number(this.pageSize)"
        layout="total,pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <AddLoggersDialog ref="addLoggerRef"></AddLoggersDialog>

    <SelectColumns ref="selectColumnsRef"></SelectColumns>
    <AddDeviceFilterDialog
      ref="filterRef"
      @filters="getSensorList"
    ></AddDeviceFilterDialog>
    <ShowShipmentListDialog ref="shipmentListRef"></ShowShipmentListDialog>
  </div>
</template>

<script>
import AddLoggersDialog from "../logger/modal/AddLoggersDialog.vue";
import SelectColumns from "../components/modal/SelectColumns.vue";
import AddDeviceFilterDialog from "../logger/modal/AddDeviceFilterDialog.vue";
import ShowShipmentListDialog from "./modal/ShowShipmentListDialog.vue";
import BatteryInfo from "../components/BatteryInfo.vue";

import { getSensorListApi } from "@/api/api.js";

export default {
  name: "Logger",
  data() {
    return {
      tableData: [],
      loggerSelection: [],
      search: "",
      total: 0,
      pageSize: 10,
      pageNum: 1,
      timezone: 0,
      filters: [],
      deviceFilterField: [],
      dashboardStatus: 1,
      isChina: true,
      deviceParams: {
        sortList: [
          {
            name: "refreshDate",
            sort: "desc",
          },
        ],
      },
    };
  },
  methods: {
    addLoggers() {
      this.$refs.addLoggerRef.showDialog();
    },

    selectColumnsBtn() {
      this.$refs.selectColumnsRef.showDialog();
    },

    deleteFilter(index) {
      console.log("index: ", index);
      console.log("this.deviceFilterField: ", this.deviceFilterField);
      // 检查 this.sceneFilterField 是否存在且是一个数组
      if (this.deviceFilterField && Array.isArray(this.deviceFilterField)) {
        // 获取要删除的 filter 的 field
        const fieldToDelete = this.deviceFilterField[index].field;

        // 检查 this.filters 是否存在且是一个数组
        if (this.filters && Array.isArray(this.filters)) {
          // 从 filters 数组中移除具有相同 field 的 filter
          this.filters = this.filters.filter(
            (filter) => filter.field !== fieldToDelete
          );

          // 从 deviceFilterField 数组中移除当前项
          this.deviceFilterField.splice(index, 1);

          console.log("this.filters: ", this.filters);

          // 调用 getSceneList 方法更新场景列表
          this.getSensorList(this.filters);
        } else {
          console.error("filters为空");
        }
      } else {
        console.error("deviceFilterField为空");
      }
    },
    filterBtn() {
      this.$refs.filterRef.showDialog();
    },
    goDeviceDetail(row) {
      let obj = {
        deviceId: row.deviceId,
      };
      this.goPages("/loggerDetail", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },

    getSensorList(filters) {
      console.log("filters: ", filters);
      this.filters = filters;
      let params = {
        ...this.deviceParams,
        timezone: this.timezone,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        filters: this.filters,
        dashboardStatus: this.dashboardStatus,
        // keyword: "string",
        // userId: "string",
      };
      getSensorListApi(params).then((r) => {
        console.log("=====", r);
        this.tableData = r.data.records || [];
        this.total = r.data.total;
        this.deviceFilterField = filters || [];
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.rows = val;
      this.$nextTick(() => {
        this.loadData();
      });
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getSensorList();
    },
    goShipmentDetail(row) {
      console.log("row: ", row);
      //  this.$refs.addLoggerRef.showDialog();
      // this.goPages("/loggerDetail", { deviceId: row.deviceId });

      // let obj = {
      // deviceId: row.deviceId,
      // };
      this.goPages("/shipmentDetail", {
        data: encodeURIComponent(JSON.stringify(row)),
      });
    },
    goShipmentList(row) {
      console.log("row: ", row);
      this.$refs.shipmentListRef.showDialog(row.deviceId);
    },
    generatePopoverContent(row) {
      const address = row.address || "-";
      const formattedTime = this.$options.filters.formatDateWithTimeZone(
        row.addressTime,
        this.setSelectedTimeZone,
        this.setSelectedLanguage
      );
      return `${address} ${formattedTime}`;
    },
    getRowStyle({ row }) {
      console.log("row: ", row.status);
      if (row.status === 9) {
        return {
          "background-color": "#f5f7fa",
          color: "#c0c4cc",
          cursor: "not-allowed",
        };
      }
      return {};
    },

    shouldShowPopover(text, length) {
      // 如果文本长度超过16个字符则返回true
      return text && text.length > length;
    },
    handleSortChange({ column, prop, order }) {
      console.log("handleSortChange--order: ", order);
      console.log("handleSortChange--column: ", column);
      console.log("handleSortChange--prop: ", prop);
      // if (prop === "battery.timestamp") {
      //   this.sortData(order);
      // }
      // 更新排序参数
      this.deviceParams.sortList = [
        {
          name: prop,
          sort: order === "ascending" ? "asc" : "desc",
        },
      ];

      // 重新加载数据
      this.getSensorList();
    },
  },
  props: {},
  components: {
    AddLoggersDialog,
    SelectColumns,
    AddDeviceFilterDialog,
    ShowShipmentListDialog,
    BatteryInfo,
  },
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
      this.getSensorList();
    },
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    this.timezone = this.setSelectedTimeZone;

    // this.filters=this.$route.query.filters||[];
    this.dashboardStatus = this.$route.query.dashboardStatus || 1;
    // console.log("this.dashboardStatus: ", this.dashboardStatus);
    // console.log('this.filters: ', this.filters);
    this.getSensorList(this.filters);
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  // padding: 10px 20px;
  padding: 10px 68px 0 38px;

  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }
  .btn-layout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -20px;
    margin-bottom: 20px;
  }
  .table-layout {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddfe6;
    border-radius: 8px;
    .filter-header {
      padding: 0 20px;
      background: white;
      height: 60px;
      line-height: 60px;
      display: flex;
      flex-direction: row;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      justify-content: space-between;
      align-items: center;
      .search-style {
        width: 500px;
        .el-input .el-input--mini .el-input-group .el-input-group--append {
          height: 42px;
        }
        /deep/ .el-input__inner {
          background: white;
        }
      }
      .filter-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20px;
        line-height: 20px;
        img {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
        .btn {
          height: 20px;
          line-height: 20px;
          color: #1a72f4;
          cursor: pointer;
        }
      }
    }
    .pagination-box {
      border-top: 1px solid #dddfe6;
      border-bottom: 1px solid #dddfe6;
      padding: 0 20px;
      background: white;
      height: 60px;
      line-height: 60px;

      display: flex;
      flex-direction: row;
      align-items: center;

      .pagination {
        margin-top: 30px;
      }
    }
  }
}
.title {
  font-size: 28px;
  font-weight: 500;
  // margin-bottom: 8px;
  margin: 15px 0;
}
.measurements-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.location-col {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.logger-detail {
  color: #1a72f4;
  text-decoration: underline;
  cursor: pointer;
}
.shipment-detail {
  color: #1a72f4;
  cursor: pointer;
  .more-style {
    color: #409eff;
    cursor: pointer;
  }
}
.alert-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  img {
    margin: 0 5px;
  }
}

.filter-box {
  display: flex;
  height: auto; /* 让高度根据内容自动调整 */
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
  flex-wrap: wrap; /* 允许换行 */
  gap: 16px; /* 使用 gap 来控制子元素之间的间距 */
  width: 100%;
  .filter-item {
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    margin: 10px;
    background: #e6e6e6;
    font-size: 12px;
    border-radius: 16px;
    color: #101010;
    display: flex;
    align-items: center;
    white-space: nowrap; /* 确保文本不会换行 */
    img {
      width: 16px;
      margin-left: 5px;
      height: 16px;
    }
  }
}
.btn-style {
  background: #1a72f4;
  padding: 10px;

  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  .tip {
    color: white;
  }
}
.more-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
