<!--
 * @Author       : JiangChao
 * @Date         : 2024-11-08 09:05:41
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-02 15:04:49
 * @Description  : 运单列表弹窗
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="85%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("shipments") }}
      </div>
    </template>
    <!-- 表格 -->
    <el-table
      :data="shipmentList"
      style="width: 100%"
      border
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      :cell-style="{ 'text-align': 'center' }"
      :empty-text="$t('no_data')"
    >
      <el-table-column :label="$t('table_key_shipment_name')" prop="name">
        <template v-slot:default="scope">
          <span class="organ-detail" @click="goShipmentDetail(scope.row)">
            {{ scope.row.name }}</span
          >
        </template>
      </el-table-column>

      <el-table-column :label="$t('shipment_id')" prop="shipmentId">
      </el-table-column>
      <el-table-column :label="$t('alert_status')" prop="alarmNum">
        <template v-slot:default="scope">
          <div v-if="scope.row.alarmInd == 1" class="alert-style">
            <img src="@/assets/ic_alert.png" width="15" /><span>{{
              scope.row.alarmNum
            }}</span>
          </div>
          <div v-else-if="scope.row.alarmInd == 0" class="alert-style">
            <img src="@/assets/ic_no_alert.png" width="15" /><span>{{
              $t("no_alert")
            }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('table_key_shipment_status')"
        prop="status"
        width="130px"
      >
        <template v-slot:default="scope">
          <div class="status-style">
            <div
              class="point"
              :style="
                scope.row.status == '1'
                  ? 'background: #02C06F;'
                  : 'background: #ed6e36;'
              "
            ></div>
            <span v-if="scope.row.status == '1'">{{ $t("tab_on_going") }}</span>

            <span v-if="scope.row.status == '0'" style="color: #ed6e36">{{
              $t("tab_not_start")
            }}</span>
            <span v-if="scope.row.status == '9'" style="color: #ed6e36">{{
              $t("tab_ended")
            }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('start_time')" prop="startTime">
        <template v-slot:default="scope">
          {{
            scope.row.startTime
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('end_time')" prop="endTime">
        <template v-slot:default="scope">
          {{
            scope.row.endTime
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('duration')" prop="duration">
        <template v-slot:default="scope">
          {{ scope.row.duration }}
        </template>
      </el-table-column>

      <!-- <el-table-column
        :label="$t('logger')"
        prop="deviceInfoList"
        width="220px"
      >
        <template v-slot:default="scope">
          <div v-if="scope.row.deviceInfoList.length > 0">
            <span
              v-for="device in scope.row.deviceInfoList"
              :key="device.deviceId"
              @click="goDeviceDetail(device)"
              class="device-detail"
            >
              {{ formatDeviceName(device) }}<br />
            </span>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </el-table-column> -->
      <el-table-column :label="$t('note')" prop="remark" min-width="150">
        <template v-slot:default="scope">
          {{ scope.row.remark}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: center"
      background
      @current-change="handleCurrentChange"
      :current-page="Number(this.pageNum)"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="Number(this.pageSize)"
      layout="total,pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-dialog>
</template>

<script>
import { getDeviceShipmentListByShipmentIdApi } from "@/api/api.js";

export default {
  name: "ShowShipmentListDialog",
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      isChina: true,
      shipmentList: [],
      total: 0,
      pageSize: 10,
      pageNum: 1,
      deviceId: "",
    };
  },
  methods: {
    showDialog(id) {
      this.deviceId = id;
      this.dialogFormVisible = true;
      this.getShipmentList();
    },

    getShipmentList() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        deviceId: this.deviceId,
      };
      getDeviceShipmentListByShipmentIdApi(params).then((r) => {
        console.log("=====", r);
        this.shipmentList = r.data.records || [];
        this.total = r.data.total;
      });
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      // this.queryParams.page.pageIndex = val;
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getShipmentList();
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
  },
  created() {},
};
</script>

<style scoped lang="less">
.form {
  display: flex;
  justify-content: right;
  padding-bottom: 20px;
}
</style>
