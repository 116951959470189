<!--
 * @Author       : JiangChao
 * @Date         : 2024-10-29 21:10:27
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-02 15:16:08
 * @Description  : 设备卡片
-->
<template>
  <div class="card-box">
    <div class="title">
      <span class="font-heavy" style="color: #409eff;"
        >{{ item.deviceName }}(#{{ item.deviceId }})</span
      >
      <img
        src="@/assets/ic_edit.png"
        alt=""
        srcset=""
        @click="editDeviceName(item.deviceId)"
      />
    </div>
    <div class="card">
      <div class="battery-box">
        <img src="@/assets/ic_example.png" />
        <div class="battery-info">
          <!-- <img src="@/assets/ic_battery_full.png" width="20" alt="" />
          <span>{{ item.deviceDataInfo.battery.value || 0 }}%</span> -->
          <!-- 
          <img
            v-if="
              Math.round(item.deviceDataInfo.battery.value) > 90 &&
                Math.round(item.deviceDataInfo.battery.value) <= 100
            "
            src="@/assets/ic_battery_full.png"
            width="20"
          />
          <img
            v-if="
              Math.round(item.deviceDataInfo.battery.value) > 50 &&
                Math.round(item.deviceDataInfo.battery.value) <= 90
            "
            src="@/assets/ic_battery_half.png"
            width="20"
          />
          <img
            v-if="
              Math.round(item.deviceDataInfo.battery.value) > 20 &&
                Math.round(item.deviceDataInfo.battery.value) <= 50
            "
            src="@/assets/ic_battery_low.png"
            width="20"
          />
          <img
            v-if="
              Math.round(item.deviceDataInfo.battery.value) >= 0 &&
                Math.round(item.deviceDataInfo.battery.value) <= 20
            "
            src="@/assets/ic_battery_empty.png"
            width="20"
          />

          <span style="margin-left: -2px;margin-top: 5px"
            >{{ item.deviceDataInfo.battery.value || "-" }}%</span
          > -->
          <BatteryInfo
            :value="item.deviceDataInfo.battery.value"
            :width="20"
            :marginLeft="'-2px'"
          ></BatteryInfo>
        </div>
      </div>
      <div>
        <div class="measurements">
          <!-- <div class="measurements-box"> -->
          <div class="measurements-up">
            <div class="left1">
              <span
                class="label"
                :style="item.deviceDataInfo.temp ? '' : 'color:#d9d9d9'"
                >{{ $t("temperature") }}</span
              >
              <div class="left1_1">
                <img
                  :src="
                    item.deviceDataInfo.temp ? icTemperature : icTemperatureGray
                  "
                  alt=""
                />
                <span
                  class="value font-heavy"
                  v-if="item.deviceDataInfo.temp"
                  :style="item.deviceDataInfo.temp ? '' : 'color:#d9d9d9'"
                  >{{
                    Number(item.deviceDataInfo.temp)
                      | convertTemperature(setSelectedTempType)
                  }}{{ setSelectedTempType }}</span
                >
                <span class="value" v-else-if="!item.deviceDataInfo.temp"
                  >-</span
                >
              </div>
              <span style="color: #d9d9d9">{{
                item.deviceDataInfo.refreshDate
                  | formatDateWithTimeZone(
                    setSelectedTimeZone,
                    setSelectedLanguage
                  )
              }}</span>
            </div>
            <div class="left2">
              <span
                class="label"
                :style="item.deviceDataInfo.wetness ? '' : 'color:#d9d9d9'"
                >{{ $t("humidity") }}</span
              >
              <div class="left2_2">
                <img
                  :src="
                    item.deviceDataInfo.wetness ? icHumidity : icHumidityGray
                  "
                  alt=""
                />
                <span
                  class="value font-heavy"
                  :style="item.deviceDataInfo.wetness ? '' : 'color:#d9d9d9'"
                  >{{ item.deviceDataInfo.wetness || "-" }}</span
                >
              </div>
            </div>
          </div>
          <div class="measurements-middle">
            <div class="right1">
              <span
                class="label"
                :style="item.deviceDataInfo.temp1 ? '' : 'color:#d9d9d9'"
                >{{ $t("temperature") }}</span
              >
              <div class="right1_1">
                <img
                  :src="
                    item.deviceDataInfo.temp1
                      ? icTemperature
                      : icTemperatureGray
                  "
                  alt=""
                />
                <span class="value font-heavy" v-if="item.deviceDataInfo.temp1"
                  >{{
                    Number(item.deviceDataInfo.temp1)
                      | convertTemperature(setSelectedTempType)
                  }}{{ setSelectedTempType }}</span
                >
                <span
                  class="value font-heavy"
                  :style="item.deviceDataInfo.temp1 ? '' : 'color:#d9d9d9'"
                  v-else-if="!item.deviceDataInfo.temp1"
                  >-</span
                >
              </div>
              <span
                v-if="item.deviceDataInfo.temp1 && item.deviceDataInfo.wetness1"
                style="color: #d9d9d9"
                >{{
                  item.deviceDataInfo.refreshDate
                    | formatDateWithTimeZone(
                      setSelectedTimeZone,
                      setSelectedLanguage
                    )
                }}</span
              >
              <span
                style="color: #d9d9d9"
                v-if="
                  !item.deviceDataInfo.temp1 && !item.deviceDataInfo.wetness1
                "
                >{{ $t("no_external_sensor") }}</span
              >
            </div>
            <div class="right2">
              <span
                class="label"
                :style="item.deviceDataInfo.wetness1 ? '' : 'color:#d9d9d9'"
                >{{ $t("humidity") }}</span
              >
              <div class="right2_2">
                <img
                  :src="
                    item.deviceDataInfo.wetness1 ? icHumidity : icHumidityGray
                  "
                  alt=""
                />

                <span
                  class="value font-heavy"
                  :style="item.deviceDataInfo.wetness1 ? '' : 'color:#d9d9d9'"
                  >{{ item.deviceDataInfo.wetness1 || "-" }}</span
                >
              </div>
            </div>
          </div>

          <!-- 光照、震动 -->
          <div class="measurements-down">
            <div class="left" v-if="item.deviceDataInfo.light">
              <span
                class="label"
                :style="item.deviceDataInfo.light.value ? '' : 'color:#d9d9d9'"
                >{{ $t("light_intensity") }}</span
              >
              <div class="left_1">
                <img
                  :src="item.deviceDataInfo.light.value ? icLight : icLightGray"
                  alt=""
                />
                <span
                  class="value font-heavy"
                  :style="
                    item.deviceDataInfo.light.value ? '' : 'color:#d9d9d9'
                  "
                  >{{ item.deviceDataInfo.light.value || "-" }}Lux</span
                >
              </div>
              <span style="color: #d9d9d9">{{
                item.deviceDataInfo.light.timestamp
                  | formatDateWithTimeZone(
                    setSelectedTimeZone,
                    setSelectedLanguage
                  )
              }}</span>
            </div>

            <div class="right">
              <span
                class="label"
                :style="
                  item.deviceDataInfo.shock && item.deviceDataInfo.shock.value
                    ? ''
                    : 'color:#d9d9d9'
                "
                >{{ $t("vibrate") }}</span
              >
              <div class="right_1">
                <img
                  :src="
                    item.deviceDataInfo.shock && item.deviceDataInfo.shock.value
                      ? icVibrate
                      : icVibrateGray
                  "
                  alt=""
                />
                <span
                  class="value font-heavy"
                  :style="
                    item.deviceDataInfo.shock && item.deviceDataInfo.shock.value
                      ? ''
                      : 'color:#d9d9d9'
                  "
                  >{{
                    (item.deviceDataInfo.shock &&
                      item.deviceDataInfo.shock.value) ||
                      "-"
                  }}mg</span
                >
              </div>
              <span style="color: #d9d9d9">{{
                item.deviceDataInfo.shock &&
                  item.deviceDataInfo.shock.timestamp
                    | formatDateWithTimeZone(
                      setSelectedTimeZone,
                      setSelectedLanguage
                    )
              }}</span>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import BatteryInfo from "../../components/BatteryInfo";
export default {
  name: "LoggerCard",
  data() {
    return {
      icTemperatureGray: require("@/assets/ic_temperature_gray.png"),
      icTemperature: require("@/assets/ic_temperature.png"),
      icHumidityGray: require("@/assets/ic_humidity_gray.png"),
      icHumidity: require("@/assets/ic_humidity.png"),
      icLightGray: require("@/assets/ic_light_gray.png"),
      icLight: require("@/assets/ic_light.png"),
      icVibrateGray: require("@/assets/ic_vibrate_gray.png"),
      icVibrate: require("@/assets/ic_vibrate.png"),
    };
  },
  methods: {
    editDeviceName(id) {
      console.log("editDeviceName---id: ", id);
      this.$emit("editDeviceName", id);
    },
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: { BatteryInfo },
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
      this.getAlarmList();
      this.getDetailLine();
    },

    setSelectedTempType(newVal) {
      if (newVal) {
        this.getDetailLine();
      }
    },
    setSelectedLanguage(newVal) {
      console.log("setSelectedLanguage---newVal: ", newVal);
      this.$i18n.locale = newVal;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">
.card-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  background: white;
  margin: 0 0 10px 0;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 60px;
    align-items: center;
    background: #e8e9ef;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid #bbbbbb;
  }
  img {
    width: 20px;
    height: 20px;
  }
  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    .battery-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 40px;
      img {
        width: 120px;
        height: 160px;
      }
      .battery-info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
        font-weight: 500;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .measurements {
      background: white;
      // border-bottom-left-radius: 8px;
      // border-bottom-right-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 250px;
      height: 300px;
      .measurements-up {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 10px;
        .left1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          .left1_1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0;
            img {
              width: 26px;
              height: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
        .left2 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          .left2_2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0;
            img {
              width: 26px;
              height: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
      }

      .measurements-middle {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 10px;

        .right1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          // margin-left: 40px;

          .right1_1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0;
            img {
              width: 26px;
              height: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
        .right2 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          .right2_2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0;
            img {
              width: 26px;
              height: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
      }

      // .h-line {
      //   width: 100%;
      //   height: 1px;
      //   background: #ececec;
      // }

      .measurements-down {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 10px;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;

          .left_1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0;
            img {
              width: 26px;
              height: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          // margin-left: 40px;
          .right_1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0;
            img {
              width: 26px;
              height: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
      }
    }
  }
}
</style>
