<!--
 * @Author       : JiangChao
 * @Date         : 2025-01-08 10:40:12
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-18 13:46:02
 * @Description  : 设备详情页面操作按钮
-->
<template>
  <!-- cursor: not-allowed -->
  <!-- 显示开启：0，4  显示关闭：1，2 -->
  <div class="box">
    <div class="up">
      <div class="function-box">
        <!-- :style="
            (cycleInd == 1 && deviceBaseInfo.status == 0) ||
            deviceBaseInfo.status == 3 ||
            (cycleInd == 1 && deviceBaseInfo.status == 4) ||
            (cycleInd == 1 && deviceBaseInfo.status == 6)
              ? 'cursor: pointer'
              : 'cursor: not-allowed'
          " -->
        <div
          v-if="
            deviceBaseInfo.status == 0 ||
              deviceBaseInfo.status == 3 ||
              deviceBaseInfo.status == 4 ||
              deviceBaseInfo.status == 6
          "
          class="function-item"
          @click="startRecording"
          :style="
            deviceBaseInfo.status == 3 ||
            (cycleInd == 1 && deviceBaseInfo.cmdStatus == 6) ||
            (cycleInd == 1 && deviceBaseInfo.cmdStatus == 7)
              ? 'cursor: not-allowed'
              : 'cursor: pointer'
          "
        >
          <!-- cycleInd是否重复使用设备标识 1:是，0:否 0时告警规则不允许编辑 -->
          <!-- :src="
              (cycleInd == 1 && deviceBaseInfo.status == 0) ||
              deviceBaseInfo.status == 3 ||
              (cycleInd == 1 && deviceBaseInfo.status == 4) ||
              (cycleInd == 1 && deviceBaseInfo.status == 6)
                ? icStart
                : icStartGray
            " -->
          <img
            :src="
              deviceBaseInfo.status == 3 ||
              (cycleInd == 1 && deviceBaseInfo.cmdStatus == 6) ||
              (cycleInd == 1 && deviceBaseInfo.cmdStatus == 7)
                ? icStopGray
                : icStop
            "
            alt=""
            srcset=""
          />
          <!-- :style="
              (cycleInd == 1 && deviceBaseInfo.status == 0) ||
              deviceBaseInfo.status == 3 ||
              (cycleInd == 1 && deviceBaseInfo.status == 4) ||
              (cycleInd == 1 && deviceBaseInfo.status == 6)
                ? 'color:#1a72f4'
                : 'color:#c0c2cb'
            " -->
          <span
            :style="
              deviceBaseInfo.status == 3 ||
              (cycleInd == 1 && deviceBaseInfo.cmdStatus == 6) ||
              (cycleInd == 1 && deviceBaseInfo.cmdStatus == 7)
                ? 'color:#c0c2cb'
                : 'color:#1a72f4'
            "
            >{{ $t("start_record") }}</span
          >
        </div>

        <!-- (cycleInd == 1 && deviceBaseInfo.status == 1) ||
            (cycleInd == 1 && deviceBaseInfo.status == 2) ||
            (cycleInd == 1 && deviceBaseInfo.status == 3) ||
            (cycleInd == 1 && deviceBaseInfo.status == 9) -->
        <div
          v-else-if="
            deviceBaseInfo.status == 1 ||
              deviceBaseInfo.status == 2 ||
              deviceBaseInfo.status == 3 ||
              deviceBaseInfo.status == 7 ||
              deviceBaseInfo.status == 9
          "
          class="function-item"
          @click="stopRecording"
          :style="
            (cycleInd == 1 && deviceBaseInfo.cmdStatus == 6) ||
            (cycleInd == 1 && deviceBaseInfo.cmdStatus == 7)
              ? 'cursor: not-allowed'
              : 'cursor: pointer'
          "
        >
          <!-- (cycleInd == 1 && deviceBaseInfo.status == 1) ||
              (cycleInd == 1 && deviceBaseInfo.status == 2) ||
              (cycleInd == 1 && deviceBaseInfo.status == 3) ||
              (cycleInd == 1 && deviceBaseInfo.status == 9) -->
          <img
            :src="
              (cycleInd == 1 && deviceBaseInfo.cmdStatus == 6) ||
              (cycleInd == 1 && deviceBaseInfo.cmdStatus == 7)
                ? icStopGray
                : icStop
            "
            alt=""
            srcset=""
          />
          <!-- (cycleInd == 1 && deviceBaseInfo.status == 1) ||
              (cycleInd == 1 && deviceBaseInfo.status == 2) ||
              (cycleInd == 1 && deviceBaseInfo.status == 3) ||
              (cycleInd == 1 && deviceBaseInfo.status == 9) -->
          <span
            :style="
              (cycleInd == 1 && deviceBaseInfo.cmdStatus == 6) ||
              (cycleInd == 1 && deviceBaseInfo.cmdStatus == 7)
                ? 'color:#c0c2cb'
                : 'color:#1a72f4'
            "
            >{{ $t("stop_record") }}</span
          >
        </div>

        <div v-if="isDelete" class="function-item">
          <img src="@/assets/ic_delete_gray.png" alt="" srcset="" />
          <span :style="isDelete ? 'color:#c0c2cb' : 'color:#ed6e36'">{{
            $t("delete")
          }}</span>
        </div>

        <div v-else-if="!isDelete" class="function-item" @click="removeDevice">
          <img src="@/assets/ic_delete_orange.png" alt="" srcset="" />
          <span :style="isDelete ? '' : 'color:#ed6e36'">{{
            $t("delete")
          }}</span>
        </div>
        <!-- pointer-events: none -->
        <div v-if="isDiscard" class="function-item" style="cursor: not-allowed">
          <img src="@/assets/ic_discard_gray.png" alt="" srcset="" />
          <span :style="isDiscard ? 'color:#c0c2cb' : 'color:#ed6e36'">{{
            $t("deprecated")
          }}</span>
        </div>

        <div
          v-else-if="!isDiscard"
          class="function-item"
          @click="discardDevice"
        >
          <img src="@/assets/ic_discard_orange.png" alt="" srcset="" />
          <span :style="!isDiscard ? 'color:#ed6e36' : 'color:#c0c2cb'">{{
            $t("deprecated")
          }}</span>
        </div>

        <!-- <div v-if="isShare" class="function-item" @click="shareLogger()">
      <img src="@/assets/ic_share_gray.png" alt="" srcset="" />
      <span :style="isShare ? 'color:#c0c2cb' : 'color:#ed6e36'">{{
        $t("cancel_share")
      }}</span>
    </div>

    <div v-else-if="!isShare" class="function-item" @click="shareLogger()">
      <img src="@/assets/ic_share.png" alt="" srcset="" />
      <span :style="!isShare ? '' : 'color:#c0c2cb;cursor: not-allowed '">{{ $t("share") }}</span>
    </div> -->

        <div
          @click="shareLogger()"
          class="function-item"
          :style="shareInd === 1 ? '' : 'cursor: not-allowed '"
        >
          <img :src="shareInd === 1 ? icShare : icShareGray" alt="" srcset="" />
          <span :style="shareInd === 1 ? '' : 'color:#c0c2cb;'">{{
            $t("share")
          }}</span>
        </div>
      </div>
      <!-- D Starting( code:6,"点击开始按钮后的状态")desc: -->
      <!-- "点击停止按钮后的状态")D stopping( code:7, desc:. -->
      <div class="tip">
        <div
          class="point"
          v-if="deviceBaseInfo.cmdStatus == 6 || deviceBaseInfo.cmdStatus == 7"
          style="background: #030303 "
        ></div>
        <span v-if="deviceBaseInfo.cmdStatus == 6">{{
          $t("will_start_recording")
        }}</span>
        <span v-if="deviceBaseInfo.cmdStatus == 7">
          {{ $t("will_stop_recording") }}</span
        >
        <!-- "will_start_recording": "设备即将在下次数据上传之后开始记录数据.", -->
        <!-- "will_stop_recording": "设备即将在下次数据上传之后停止记录数据.", -->
      </div>
    </div>

    <ShareLoggerDialog
      ref="shareRef"
      @isShare="handleIsShare"
    ></ShareLoggerDialog>
  </div>
</template>

<script>
import {
  stopRecordingApi,
  removeDetailDeviceApi,
  discardDeviceApi,
  startRecordingApi,
} from "@/api/api.js";
import ShareLoggerDialog from "../modal/ShareLoggerDialog.vue";
export default {
  name: "FunctionBox",
  data() {
    return {
      icShare: require("@/assets/ic_share.png"),
      icShareGray: require("@/assets/ic_share_gray.png"),
      icStart: require("@/assets/ic_start.png"),
      icStartGray: require("@/assets/ic_start_gray.png"),
      icStop: require("@/assets/ic_stop.png"),
      icStopGray: require("@/assets/ic_stop_gray.png"),
      isDelete: false,
      isRecord: false,
      isShare: false,
      isDiscard: false,
      timezone: 0,
    };
  },
  methods: {
    removeDevice() {
      const alertContent = `
    <div>
      <span style="font-size:16px;">${this.$t("delete_device_tip")}</span>
      <br />
      <span style="color:gray;font-size:12px;">${this.$t(
        "delete_device_sub_tip"
      )}</span>
    </div>
  `;
      this.$alert(alertContent, this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        let params = {
          deviceId: this.deviceId,
          timezone: this.timezone,
        };
        removeDetailDeviceApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("remove_logger_success"));
          this.isDelete = !this.isDelete;
          // this.dialogFormVisible = false;

          this.$emit("goHomeBoard");
        });
      });
    },

    stopRecording() {
      if (this.cycleInd === 0) {
        return;
      }
      if (
        this.deviceBaseInfo.cmdStatus == 6 ||
        this.deviceBaseInfo.cmdStatus == 7
      ) {
        return;
      }
      const alertContent = `
    <div>
      <span style="font-size:16px;">${this.$t("stop_device_tip")}</span>
      <br />
      <span style="color:gray;font-size:12px;">${this.$t(
        "stop_device_sub_tip"
      )}</span>
    </div>
  `;
      this.$alert(alertContent, this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        let params = {
          deviceId: this.deviceId,
          timezone: this.timezone,
        };
        stopRecordingApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("stop_record"));
          // this.dialogFormVisible = false;
          // this.isRecord = true;
          this.$emit("getDetailInfo");
        });
      });
    },
    discardDevice() {
      const alertContent = `
    <div>
      <span style="font-size:16px;">${this.$t("discard_tip")}</span>
      <br />
      <span style="color:gray;font-size:12px;">${this.$t(
        "discard_sub_tip"
      )}</span>
    </div>
  `;
      this.$alert(alertContent, this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,
        type: "warning",
        dangerouslyUseHTMLString: true, // 允许使用 HTML 字符串
      }).then(() => {
        let params = {
          deviceId: this.deviceId,
          timezone: this.timezone,
        };
        discardDeviceApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("operation_successful"));
          this.isDiscard = !this.isDiscard;
          this.$emit("goHomeBoard");
        });
      });
    },
    startRecording() {
      if (this.cycleInd === 0) {
        return;
      }
      if (
        this.deviceBaseInfo.cmdStatus == 6 ||
        this.deviceBaseInfo.cmdStatus == 7
      ) {
        return;
      }
      let params = {
        deviceId: this.deviceId,
        timezone: this.timezone,
      };
      startRecordingApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("start_recording"));
        // this.isRecord = false;
        this.$emit("getDetailInfo");
      });
    },

    shareLogger() {
      if (this.shareInd === 1) {
        this.$refs.shareRef.showDialog(this.deviceId);
      }
    },
    handleIsShare(val) {
      console.log("val: ", val);
      this.isShare = val;
    },
  },
  props: {
    deviceBaseInfo: {
      type: Object,
      default: () => {},
    },
    deviceId: {
      type: String,
      default: "",
    },
    cycleInd: {
      type: Number,
      default: 0,
    },
    shareInd: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ShareLoggerDialog,
  },
  watch: {
    setSelectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
    },

    setSelectedLanguage(newVal) {
      console.log("setSelectedLanguage---newVal: ", newVal);
      this.$i18n.locale = newVal;
    },
  },
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
  },
  mounted() {
    this.timezone = this.setSelectedTimeZone;
  },
  created() {},
};
</script>

<style scoped lang="less">
.box {
  display: flex;
  flex-direction: column;
  .up {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 40px 0 20px 0;
    border-radius: 8px;
    .function-box {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      margin-top: 4px;

      .function-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        img {
          width: 40px;
        }
        span {
          font-size: 14px;
          color: #1a72f4;
        }
      }
    }

    .tip {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      margin-left: 50px;
      color: #030303;
      .point {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        margin-right: 4px;
      }
    }
  }
}
</style>
